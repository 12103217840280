import CopyIcon from '@/component/Icons/CopyIcon';
import { useCouldEditProfile } from '@/hooks/profile';
import { getProfileRouter } from '@cyber-co/utils';
import { Button, Flex, Text } from '@cyberlab/uikit';
import { useRouter } from 'next/router';
import BlockWrapper from './core/BlockWrapper';

function ProfileLink({ handle }: { handle: string }) {
  const { couldEdit } = useCouldEditProfile();

  if (!couldEdit) return null;

  const router = useRouter();

  return (
    <BlockWrapper
      title={'Profile Link'}
      key='profile-link'
      contentProps={{ gap: 3, sx: { p: 2 } }}
      editButton={
        <Button
          variant='outlined'
          size='tiny'
          onClick={() => {
            router.push('/custom-link');
          }}
        >
          Customize link
        </Button>
      }
    >
      <Flex alignItems='center' justifyContent='space-between'>
        <Text>link3.to{getProfileRouter(handle)}</Text>
        <CopyIcon text={`link3.to${getProfileRouter(handle)}`} />
      </Flex>
    </BlockWrapper>
  );
}

export default ProfileLink;
