import { DEFAULT_BLOCK_TITLE } from '@/configs/profile';
import { useCouldEditProfile } from '@/hooks/profile/useCouldEditProfile';
import { fixHttpLink } from '@cyber-co/utils';
import { BlockType, Link, LinkBlock } from '@cyber/service/pwa';
import { Stack, Text } from '@cyberlab/uikit';
import { Icon } from '@link3-pwa/icons';
import dynamic from 'next/dynamic';
import { IBlockProps } from '../../types';
import BlockWrapper from '../core/BlockWrapper';
import EditButton from '../core/EditButton';

const LinksEdit = dynamic(() => import('./Links.edit'), { ssr: false });

interface ILinkBlockProps extends IBlockProps {
  data?: LinkBlock;
}

const LinkItem = ({ data }: { data: Link }) => (
  <Stack
    flexDirection='row'
    component='a'
    href={fixHttpLink(data.link)}
    target='_blank'
    alignItems='center'
    justifyContent='space-between'
    sx={{
      textDecoration: 'none',
    }}
  >
    <Text variant='body1' color='text.100'>
      {data.title}
    </Text>

    <Icon name='icon-arrow-tiny-right' />
  </Stack>
);

function Links(props: ILinkBlockProps) {
  const { data, profileId, isCreate } = props;
  const { couldEdit } = useCouldEditProfile();

  return (
    <BlockWrapper
      title={data?.displayName || DEFAULT_BLOCK_TITLE[BlockType.Link]}
      key={data?.id}
      contentProps={{ gap: 5, sx: { p: isCreate ? 0 : undefined } }}
      editButton={
        <EditButton<LinkBlock>
          profileId={profileId}
          data={data}
          hidden={!couldEdit}
          Modal={LinksEdit}
          isCreate={isCreate}
        />
      }
      noDivider={isCreate}
    >
      {data?.links?.map((link, index) => <LinkItem key={index} data={link} />)}
    </BlockWrapper>
  );
}

export default Links;
