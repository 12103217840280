import OrgIcon from '@/component/Icons/OrgIcon';
import { useCouldEditProfile } from '@/hooks/profile';
import { fixTwitterLink } from '@cyber-co/utils';
import { Education } from '@cyber/service/pwa';
import { Avatar, Flex, Stack, Text } from '@cyberlab/uikit';
import { Icon } from '@link3-pwa/icons';
import { fromUnixTime, getYear } from 'date-fns';
import Link from 'next/link';

function EducationItem(props: { data: Education; onEdit: (id: string) => void }) {
  const { data } = props;
  const { onEdit } = props;
  const { couldEdit } = useCouldEditProfile();

  const start = fromUnixTime(data.startDate);
  const end = data.endDate ? fromUnixTime(data.endDate) : undefined;

  const renderTitle = () => {
    if (!data.degree && !data.fieldOfStudy) return null;

    return (
      <Text variant='body2' color='text.100'>
        {`${data.degree ? data.degree + ' - ' : ''}`}
        {data.fieldOfStudy}
      </Text>
    );
  };

  const getHref = () => {
    if ((!data.school.twitterId && !data.school.profileId) || couldEdit) return 'javascript:;';

    if (data.school.profileId) return `/${data.school.profileId}`;

    return fixTwitterLink({ id: data.school.twitterId });
  };

  return (
    <Link href={getHref()}>
      <Flex gap={1.5} alignItems='start'>
        <Avatar sx={{ width: 40, height: 40, flexShrink: 0 }} variant='rounded' src={data.school.avatar} />

        <Stack gap={0.5} width='100%'>
          <Flex alignItems='center' gap={1}>
            <Text bold variant='body1' color='text.100'>
              {data.school.displayName}
            </Text>

            {data.school.profileId ? <OrgIcon size={18} /> : null}
          </Flex>

          {renderTitle()}

          <Text variant='body2' color='text.250'>
            {getYear(start)} - {end ? getYear(end) : 'Present'}
          </Text>
        </Stack>

        {couldEdit ? <Icon name='icon-edit-line' onClick={() => onEdit(data.id)} /> : null}
      </Flex>
    </Link>
  );
}

export default EducationItem;
