import { revalidateSSGPage } from '@/utils';
import { getProfileQueryParams } from '@cyber-co/utils';
import {
  UpdateBlockVisibilityInput,
  UpdateBlockVisibilityResponse_Status,
  api,
  useUpdateBlockVisibilityMutation,
} from '@cyber/service/pwa';
import { toast } from '@cyberlab/uikit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

type TParams = UpdateBlockVisibilityInput & { handle: string };

export const useUpdateBlockVisibility: () => [
  updater: (p: TParams) => Promise<{ isSuccess: boolean }>,
  result: any,
] = () => {
  const dispatch: any = useDispatch();

  const [updateVisibility, result] = useUpdateBlockVisibilityMutation();

  const handleUpdate = useCallback(
    async ({ id, handle, visibility }: TParams) => {
      const res = await updateVisibility({
        input: { id, visibility },
      }).then((data) => {
        if ('data' in data && data.data.updateBlockVisibility.status === UpdateBlockVisibilityResponse_Status.Success) {
          return { isSuccess: true };
        }
        return { isSuccess: false };
      });

      if (res.isSuccess) {
        await dispatch(api.endpoints.getProfile.initiate(getProfileQueryParams(handle), { forceRefetch: true }));
        toast.success('Update Successfully!');
        revalidateSSGPage(handle);
      }

      return res;
    },
    [updateVisibility],
  );

  return [handleUpdate, result];
};
