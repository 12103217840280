import Headline from '@/component/Headline';
import User from '@/component/User';
import { PerProfile, ProfileType, Recommendation } from '@cyber/service/pwa';
import { Box, Stack, Text } from '@cyberlab/uikit';
import { ReactNode } from 'react';

function RecommendationItem(props: { data: Recommendation; renderIcon?: (data: Recommendation) => ReactNode }) {
  const { data, renderIcon } = props;

  const profile = data.user.profile as PerProfile;

  return (
    <Stack gap={1} sx={{ opacity: data?.hidden ? 0.5 : 1 }}>
      <Box position='relative'>
        <User
          profileHandle={data.user.lightInfo.handle}
          profileId={profile.id}
          wrapperProps={{ gap: 1.5, width: '100%' }}
          avatar={data.user.lightInfo.avatar}
          displayName={data.user.lightInfo.displayName}
          type={ProfileType.Personal}
          desc={<Headline data={profile.headline} isVerified={!!data.user.lightInfo.attestation?.uid} />}
        />

        <Box position='absolute' top={0} right={0}>
          {renderIcon && renderIcon(data)}
        </Box>
      </Box>

      <Text pl='52px' variant='body2' color='text.100'>
        {data.content}
      </Text>
    </Stack>
  );
}

export default RecommendationItem;
