import { Box, Flex, Skeleton, Stack, Text, useTheme } from '@cyberlab/uikit';
import { ReactNode, useState } from 'react';
import { Tweet } from 'react-twitter-widgets';

const getTweetId = (tweetLink?: string) => {
  if (!tweetLink) return '';

  const splitedLink = tweetLink.split('?')[0].split('/');
  return splitedLink[splitedLink.length - 1];
};

const TweetSkeleton = ({ isReady }: { isReady: boolean }) => {
  return (
    <Stack
      gap={1}
      sx={{ display: isReady ? 'none' : 'flex', border: '1px solid', borderColor: 'border.100', borderRadius: 1, p: 3 }}
    >
      <Flex alignItems='center' gap={0.5}>
        <Skeleton variant='circular' sx={{ height: 40, width: 40 }} />

        <Stack gap={0.5}>
          <Skeleton>
            <Text variant='caption'>asdasdsad ka jdl</Text>
          </Skeleton>
          <Skeleton>
            <Text variant='caption'>asdasdsad ka </Text>
          </Skeleton>
        </Stack>
      </Flex>

      <Skeleton>
        <Text>asdasdsad ka jdl kaasdsad ka jdl kajsd</Text>
      </Skeleton>

      <Skeleton>
        <Text>asdasdsad ka jdl kajsdsad ka jdl kajsd</Text>
      </Skeleton>

      {/* <Skeleton sx={{ width: '100%', height: 150, m: 0 }} /> */}

      <Skeleton>
        <Text variant='caption'>asdasda a asd sad k as a sada </Text>
      </Skeleton>
    </Stack>
  );
};

function EmbededTweet(props: { editButton: ReactNode; link: string }) {
  const { editButton, link } = props;
  const themeMode = useTheme().palette.mode;

  const [isReady, toggleIsReady] = useState(false);

  return (
    <Box sx={{ '.twitter-tweet': { margin: '0px !important' } }} position='relative'>
      {editButton}
      <Tweet
        onLoad={() => {
          toggleIsReady(true);
        }}
        tweetId={getTweetId(link)}
        options={{ width: '100%', align: 'center', theme: themeMode }}
      />

      <TweetSkeleton isReady={isReady} />
    </Box>
  );
}

export default EmbededTweet;
