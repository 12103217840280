import OrgIcon from '@/component/Icons/OrgIcon';
import { useCouldEditProfile } from '@/hooks/profile';
import { fixTwitterLink } from '@cyber-co/utils';
import { WorkExperience } from '@cyber/service/pwa';
import { Avatar, Flex, Stack, Text } from '@cyberlab/uikit';
import { Icon } from '@link3-pwa/icons';
import { fromUnixTime } from 'date-fns';
import Link from 'next/link';
import { formatTime } from '../core/date';

function WorkItem(props: { data: WorkExperience; onEdit: (id: string) => void }) {
  const { data } = props;
  const { onEdit } = props;
  const { couldEdit } = useCouldEditProfile();

  const start = fromUnixTime(data.startDate);
  const end = data.endDate ? fromUnixTime(data.endDate) : undefined;

  const getHref = () => {
    if ((!data.organization.twitterId && !data.organization.profileId) || couldEdit) return 'javascript:;';

    if (data.organization.profileId) return `/${data.organization.profileId}`;

    return fixTwitterLink({ id: data.organization.twitterId });
  };

  return (
    <Link href={getHref()}>
      <Flex gap={1.5} alignItems='start'>
        <Avatar sx={{ width: 40, height: 40, flexShrink: 0 }} variant='rounded' src={data.organization.avatar} />

        <Stack gap={0.5} width='100%'>
          <Flex gap={1} alignItems='center'>
            <Text bold variant='body1' color='text.100'>
              {data.organization.displayName}
            </Text>
            {data.organization.profileId ? <OrgIcon size={18} /> : null}
          </Flex>

          <Text variant='body2' color='text.100'>
            {data.organization.title}
          </Text>

          <Text variant='body2' color='text.250'>
            {formatTime(start, end)}
          </Text>
        </Stack>

        {couldEdit ? <Icon name='icon-edit-line' onClick={() => onEdit(data.id)} /> : null}
      </Flex>
    </Link>
  );
}

export default WorkItem;
