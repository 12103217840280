import { useLinkEmailModal } from '@/hooks';
import { redirectGetGithubCode } from '@/utils/github';
import { redirectGetTwitterCode } from '@/utils/twitter';
import { fixTwitterLink } from '@cyber-co/utils';
import {
  Contact,
  ContactType,
  LinkWeb2Response_Status,
  LoginType,
  useGetTwitterInfoByIdQuery,
  useLazyMeQuery,
  useLinkWeb2Mutation,
} from '@cyber/service/pwa';
import { toast } from '@cyberlab/uikit';
import { useCallback } from 'react';
import { useLinkedIn } from 'react-linkedin-login-oauth2';

const LINKS = {
  [ContactType.Email]: (name: string) => undefined,
  [ContactType.Twitter]: (id: string) => fixTwitterLink({ id }),
  [ContactType.Linkedin]: (name: string) => `https://www.linkedin.com/in/${name}`,
  [ContactType.Github]: (name: string) => `https://github.com/${name}`,
};

const LOGIN_TYPE = {
  [ContactType.Email]: LoginType.EmailLogin,
  [ContactType.Twitter]: LoginType.TwitterLogin,
  [ContactType.Linkedin]: LoginType.LinkedinLogin,
  [ContactType.Github]: LoginType.GithubLogin,
};
export default function useSocialMediaInfo(params: Contact) {
  const { name, contactType } = params;
  const [updateMeData] = useLazyMeQuery();
  const [linker, { isLoading }] = useLinkWeb2Mutation();

  const linkedInredirectUri = `${typeof window === 'object' && window.location.origin}/redirect/linkedin`;

  const { linkedInLogin } = useLinkedIn({
    clientId: '86z8no8b20k6qp',
    scope: 'profile openid r_basicprofile r_1st_connections_size',
    redirectUri: linkedInredirectUri,
    onSuccess: async (code) => {
      const res = await linker({
        type: LoginType.LinkedinLogin,
        token: '',
        twitterOAuthCode: { code: '', redirectUri: '' },
        linkedInOAuth: { code, redirectUri: linkedInredirectUri },
      });

      if ('data' in res) {
        if (res.data.linkWeb2.status === LinkWeb2Response_Status.Success) {
          await updateMeData();
        } else {
          switch (res.data.linkWeb2.status) {
            case LinkWeb2Response_Status.LinkedinUsed:
              toast.error('LinkedIn Account is already used, please try another one.');
              break;

            default:
              toast.error('Something went wrong, please try again later.');
          }
        }
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { open: openLinkEmailModal } = useLinkEmailModal();

  const { twitterInfo } = useGetTwitterInfoByIdQuery(
    { twitterId: name },
    {
      skip: contactType !== ContactType.Twitter,
      selectFromResult({ currentData }) {
        return {
          twitterInfo:
            contactType !== ContactType.Twitter
              ? undefined
              : {
                  ...params,
                  handle: currentData?.getTwitterHandleById.twitterHandle
                    ? `@${currentData?.getTwitterHandleById.twitterHandle}`
                    : '',
                  url: LINKS[contactType](name),
                },
        };
      },
    },
  );

  const link = useCallback(() => {
    switch (contactType) {
      case ContactType.Linkedin:
        linkedInLogin();
        break;

      case ContactType.Email:
        openLinkEmailModal({ successCallback: updateMeData });
        break;

      case ContactType.Github:
        redirectGetGithubCode();
        break;

      case ContactType.Twitter:
        redirectGetTwitterCode();
        break;

      default:
        break;
    }
  }, [contactType]);

  return {
    ...(twitterInfo || {
      ...params,
      handle: params.name,
      url: LINKS[contactType](name),
    }),
    link,
  };
}
