import PWALayout from '@/component/Layout/PWALayout';
import { useAddresses } from '@/hooks';
import Profile from '@/views/Profile';
import { api } from '@cyber/service/api';
import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';

function ProfilePage() {
  const dispatch: any = useDispatch();
  const { eoa, assetWallets } = useAddresses();

  useEffect(() => {
    if (eoa) {
      //prefetch nft data
      dispatch(
        api.util.prefetch(
          'retriveAllNFTByAddress',
          assetWallets.join(',') /*'0xd8da6bf26964af9d7eed9e03e53415d37aa96045'*/,
          {},
        ),
      );
    }
  }, [
    assetWallets,
    //  isPersonal, isAdmin
  ]);

  return <Profile />;
}

export default ProfilePage;

ProfilePage.getLayout = function getLayout(page: ReactElement) {
  // const profileMetaData = page.props?.profileMetadata;

  return (
    <PWALayout
    // metadata={getProfileMetaData(profileMetaData)}
    >
      {page}
    </PWALayout>
  );
};

// export const getStaticProps = ssgProfile;
// export function getStaticPaths() {
//   return {
//     paths: [],
//     fallback: 'blocking',
//   };
// }

// const getProfileMetaData = (data: any = {}): MetaTagsProps | undefined => {
//   if (data.type === ProfileType.Personal) {
//     const perProfile = data as PersonalProfileFragment & PerProfile;

//     return {
//       title: `Link3.to | ${perProfile.personalDisplayName.displayName}`,
//       description: perProfile.bio,
//       image: getProfileCardLink(
//         {
//           displayName: perProfile?.personalDisplayName?.displayName || '',
//           handle: perProfile?.handle || '',
//           avatar: fixNFTIPFSURL(perProfile?.personalProfilePicture?.picture),
//           reputationScore: String(perProfile?.reputation.total || 0),
//           isVerified: perProfile.isVerified,
//           title: perProfile.headline.title,
//           company: perProfile.headline.displayName,
//         },
//         true,
//       ),
//     };
//   } else if (data.type === ProfileType.Organization) {
//     const orgProfile = data as OrgProfile;

//     return {
//       title: `Link3.to | ${orgProfile.displayName}`,
//       description: orgProfile.bio,
//       image: getProfileCardLink({
//         displayName: orgProfile?.displayName || '',
//         handle: orgProfile?.handle,
//         avatar: fixNFTIPFSURL(orgProfile?.profilePicture),
//         followerCount: String(orgProfile?.organization?.followersCount || 0),
//         isVerified: orgProfile?.organization?.verification === VerificationStatus.Verified,
//         title: orgProfile?.bio || '',
//       }),
//     };
//   }
//   return undefined;
// };
