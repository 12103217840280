import { DEFAULT_BLOCK_TITLE } from '@/configs/profile';
import { useCreateBlocks } from '@/hooks/profile';
import { useCouldEditProfile } from '@/hooks/profile/useCouldEditProfile';
import { useUpdateBlockVisibility } from '@/hooks/profile/useUpdateBlockVisibility';
import { BlockType, BlockVisibility, PostBlock, useOrgPostsQuery } from '@cyber/service/pwa';
import { LoadingButton } from '@cyberlab/uikit';
import { Icon } from '@link3-pwa/icons';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { IBlockProps } from '../../types';
import BlockWrapper from '../core/BlockWrapper';
import PostList from './PostList';

const EditButton = (props: { data?: PostBlock; profileId: string; isCreate?: boolean; handle: string }) => {
  const { handle, data, profileId, isCreate } = props;

  const [isLoading, toggleIsLoading] = useState(false);

  const [updateVisibility] = useUpdateBlockVisibility();
  const [createBlock] = useCreateBlocks();

  const handleDelete = async () => {
    if (!data) return;
    toggleIsLoading(true);
    await updateVisibility({
      handle,
      id: data.id,
      visibility: BlockVisibility.BlockVisibilityPrivate,
    });
    toggleIsLoading(false);
  };

  const handleCreate = async () => {
    if (!data) return;
    toggleIsLoading(true);
    await (isCreate
      ? createBlock({
          handle,
          profileId,
          input: {
            displayName: DEFAULT_BLOCK_TITLE[BlockType.Posts],
            post: {
              enable: true,
            },
          },
        })
      : updateVisibility({
          handle,
          id: data.id,
          visibility: BlockVisibility.BlockVisibilityPublic,
        }));
    toggleIsLoading(false);
  };

  if (isCreate || data?.visibility === BlockVisibility.BlockVisibilityPrivate) {
    return (
      <LoadingButton
        loading={isLoading}
        variant='outlined'
        sx={{ px: 1, height: 28, fontSize: 12 }}
        onClick={handleCreate}
        startIcon={<Icon name='icon-eye-off-line' size={18} mt={-0.5} />}
      >
        Private
      </LoadingButton>
    );
  }

  return (
    <LoadingButton
      loading={isLoading}
      variant='outlined'
      sx={{ px: 1, height: 28, fontSize: 12 }}
      startIcon={<Icon name='icon-eye-line' size={18} mt={-0.5} />}
      onClick={handleDelete}
    >
      Public
    </LoadingButton>
  );
};

interface ILink3PostProps extends IBlockProps {
  data: PostBlock;
}
function Link3Post(props: ILink3PostProps) {
  const { data, profileId, isCreate } = props;

  const { couldEdit } = useCouldEditProfile();

  const { query } = useRouter();
  const handle = (query?.handle || '').toString();

  const { _posts } = useOrgPostsQuery(
    { handle, first: 6 },
    {
      skip: !handle || !isCreate,
      selectFromResult({ currentData }) {
        return {
          _posts: currentData?.profile.data?.creator?.posts.list,
        };
      },
    },
  );

  const posts = data?.postPage?.list || _posts;

  return (
    <BlockWrapper
      key={data.id}
      title={data.displayName || DEFAULT_BLOCK_TITLE[BlockType.Posts]}
      editButton={
        couldEdit ? <EditButton isCreate={isCreate} data={data} profileId={profileId} handle={handle} /> : null
      }
      contentProps={{ sx: { p: 0 } }}
    >
      <PostList posts={posts || []} />
    </BlockWrapper>
  );
}

export default Link3Post;
