import { Button, ButtonProps, Stack } from '@cyberlab/uikit';
import Icon from '@link3-pwa/icons';
import { ComponentType, useState } from 'react';
import { TEditModalProps } from '../../types';

type TEditButtonProps<TBlockData> = {
  hidden?: boolean;
  isCreate?: boolean;
  data?: TBlockData;
  profileId: string;
  Modal?: ComponentType<TEditModalProps & { blockData?: TBlockData }>;
  isModalOpen?: boolean;
  openModal?: () => void;
  closeModal?: () => void;
};

export const EditIcon = (props: { onClick?: any }) => (
  <Stack
    onClick={(e) => {
      if (props.onClick) {
        e.stopPropagation();
        e.preventDefault();
        props.onClick();
      }
    }}
    sx={({ palette }) => ({
      backdropFilter: 'blur(5px)',
      bgcolor: palette.mode === 'light' ? 'rgba(255, 255, 255, 0.50)' : 'rgba(0, 0, 0, 0.50)',
    })}
    borderRadius={1}
    border='1px solid #fff'
    p='2px'
    position='absolute'
    top={12}
    right={12}
  >
    <Icon name='icon-edit-line' size={18} alignItems='center' justifyContent='center' />
  </Stack>
);

export function EditIconButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      size='small'
      // variant='outlined'
      color='primary'
      sx={{ height: 28, width: 28 }}
    >
      <Icon name='icon-edit-line' />
    </Button>
  );
}

export function AddIconButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      size='small'
      variant='outlined'
      color='primary'
      sx={{ height: 28, px: 1, flexShrink: 0 }}
      startIcon={<Icon size={18} name='icon-add-circle-line' mb={0.25} />}
    >
      Add
    </Button>
  );
}

function EditButton<TBlockData>(props: TEditButtonProps<TBlockData>) {
  const {
    hidden = true,
    data,
    profileId,
    isCreate,
    Modal,
    isModalOpen: isModalOpenFromProps,
    openModal: openModalFromProps,
    closeModal: closeModalFromProps,
  } = props;

  const [isModalOpen, toggleIsModal] = useState(false);

  const openModal = () => {
    if (openModalFromProps) {
      openModalFromProps();
    } else {
      toggleIsModal(true);
    }
  };
  const closeModal = () => {
    if (closeModalFromProps) {
      closeModalFromProps();
    } else {
      toggleIsModal(false);
    }
  };

  if (hidden) return null;

  const Button = isCreate ? AddIconButton : EditIconButton;

  return (
    <>
      <Button onClick={openModal} />
      {Modal ? (
        <Modal onClose={closeModal} open={isModalOpenFromProps || isModalOpen} blockData={data} profileId={profileId} />
      ) : null}
    </>
  );
}

export default EditButton;
