import GlobalAvatar from '@/component/GlobalAvatar';
import Wrapper from '@/component/Wrapper';
import { useCouldEditProfile } from '@/hooks/profile/useCouldEditProfile';
import { Box, Flex, Tag, Text } from '@cyberlab/uikit';
import dynamic from 'next/dynamic';
import EASVerification from '../../EAS';
import { IOrgBasicInfoProps } from '../../types';
import ProfileBackground from '../core/ProfileBackground';
import SupportChains from './SupportChains';

const EditOrgBasicInfo = dynamic(() => import('./OrgBasicInfo.edit'), { ssr: false });

function OrgBasicInfo(props: IOrgBasicInfoProps) {
  const { data } = props;
  const { couldEdit } = useCouldEditProfile();

  return (
    <>
      <Box>
        <ProfileBackground src={data?.backgroundPicture} />
        <Wrapper gap={3} position='relative'>
          <GlobalAvatar bordered sx={{ mt: -8 }} size={83} src={data?.profilePicture} borderWidth={3} />

          {data?.sector ? (
            <Tag containerProps={{ position: 'absolute', top: 24, right: 24 }}>#{data.sector}</Tag>
          ) : null}

          <Flex alignItems='center' justifyContent='space-between'>
            <Flex gap={0.5} mt={-1} alignItems='center'>
              <Text variant='h1' color='text.100' bold>
                {data?.displayName}
              </Text>

              <EASVerification orgName={data?.displayName || ''} type='org' profileId={data?.id} />
            </Flex>

            {couldEdit ? <EditOrgBasicInfo data={data} /> : null}
          </Flex>

          {data?.bio ? (
            <Text variant='body2' color='text.250'>
              {data.bio}
            </Text>
          ) : null}

          <Flex gap={1.5}>
            <Text variant='body2' color='text.100'>
              {data?.organization?.followersCount || 0}
              <Text variant='body2' display='inline' color='text.250' ml={0.5}>
                Followers
              </Text>
            </Text>
          </Flex>
        </Wrapper>
      </Box>

      <SupportChains {...props} />
    </>
  );
}

export default OrgBasicInfo;
