import { Flex, StackProps, Text } from '@cyberlab/uikit';
import Icon from '@link3-pwa/icons';

export default function ShowMore({
  isShowMore,
  setShowMore,
  wrapperProps,
}: {
  isShowMore: boolean;
  setShowMore: () => void;
  wrapperProps?: StackProps;
}) {
  return (
    <Flex alignItems='center' justifyContent='center' gap={0.5} mt={3} onClick={setShowMore} {...wrapperProps}>
      <Text>{isShowMore ? 'Show less' : 'Show more'}</Text>
      <Icon name='icon-arrow-tiny-down' sx={{ transform: isShowMore ? 'rotate(180deg)' : '' }} />
    </Flex>
  );
}
