import { DEFAULT_BLOCK_TITLE } from '@/configs/profile';
import { useCreateBlocks, useUpdateBlocks } from '@/hooks/profile';
import { useCouldEditProfile } from '@/hooks/profile/useCouldEditProfile';
import { useUpdateBlockVisibility } from '@/hooks/profile/useUpdateBlockVisibility';
import { BlockType, BlockVisibility, ContactBlock, ContactCreation, ContactType, useMeQuery } from '@cyber/service/pwa';
import { LoadingButton, Stack, Text } from '@cyberlab/uikit';
import Icon from '@link3-pwa/icons';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { IBlockProps } from '../../types';
import BlockWrapper from '../core/BlockWrapper';
import ContactItem from './ContactItem';

interface IContactProps extends IBlockProps {
  data?: ContactBlock;
}

const PrivateMask = () => (
  <Stack
    gap={1.5}
    alignItems='center'
    justifyContent='center'
    top={0}
    left={0}
    width='100%'
    height='100%'
    position='absolute'
    bgcolor='red'
    sx={({ palette }) => ({
      bgcolor: palette.mode === 'light' ? 'rgba(255,255,255,0.5)' : 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(5px)',
    })}
  >
    <Icon name='icon-eye-off-line' />

    <Text textAlign='center' width={200} variant='body2'>
      Keep my contact information hidden from the public
    </Text>
  </Stack>
);

const EditButton = (props: {
  data?: ContactBlock;
  isCreate?: boolean;
  onPublic: () => void;
  onPrivate: () => void;
}) => {
  const { data, isCreate, onPublic, onPrivate } = props;

  const [isLoading, toggleIsLoading] = useState(false);

  const handlePrivate = async () => {
    toggleIsLoading(true);
    await onPrivate();
    toggleIsLoading(false);
  };

  const handleCreate = async () => {
    if (!data) return;
    toggleIsLoading(true);
    await onPublic();
    toggleIsLoading(false);
  };

  if (isCreate || data?.visibility === BlockVisibility.BlockVisibilityPrivate) {
    return (
      <LoadingButton
        loading={isLoading}
        variant='outlined'
        sx={{ px: 1, height: 28, fontSize: 12 }}
        startIcon={<Icon name='icon-eye-off-line' size={18} mt={-0.5} />}
        onClick={handleCreate}
      >
        Private
      </LoadingButton>
    );
  }

  return (
    <LoadingButton
      loading={isLoading}
      variant='outlined'
      sx={{ px: 1, height: 28, fontSize: 12 }}
      startIcon={<Icon name='icon-eye-line' size={18} mt={-0.5} />}
      onClick={handlePrivate}
    >
      Public
    </LoadingButton>
  );
};

function Contact(props: IContactProps) {
  const { data, isCreate, profileId } = props;
  const { couldEdit } = useCouldEditProfile();

  const [createBlock] = useCreateBlocks();
  const [updateBlock] = useUpdateBlocks();
  const [updateVisibility] = useUpdateBlockVisibility();

  const { query } = useRouter();
  const handle = (query?.handle || '').toString();

  const shouldCollapsed = isCreate || data?.visibility === BlockVisibility.BlockVisibilityPrivate;

  const { privateContacts, refetch } = useMeQuery(undefined, {
    skip: !couldEdit,
    selectFromResult({ currentData }) {
      const privateInfo = currentData?.me.data?.privateInfo;
      return {
        privateContacts: couldEdit
          ? [
              {
                contactType: ContactType.Email,
                name: privateInfo?.email || '',
                hidden: data?.contacts?.find((c) => c.contactType === ContactType.Email)?.hidden ?? true,
              },
              {
                contactType: ContactType.Linkedin,
                name: privateInfo?.linkedinId || '',
                hidden: data?.contacts?.find((c) => c.contactType === ContactType.Linkedin)?.hidden ?? true,
              },
              {
                contactType: ContactType.Twitter,
                name: privateInfo?.twitterId || '',
                hidden: data?.contacts?.find((c) => c.contactType === ContactType.Twitter)?.hidden ?? true,
              },
              {
                contactType: ContactType.Github,
                name: privateInfo?.githubId || '',
                hidden: data?.contacts?.find((c) => c.contactType === ContactType.Github)?.hidden ?? true,
              },
            ]
          : undefined,
      };
    },
  });

  const contacts = privateContacts || data?.contacts;

  const updateItemVisibility = async (params: Partial<ContactCreation>) => {
    if (data?.visibility === BlockVisibility.BlockVisibilityPrivate) return { isSuccess: true };

    const currentState: ContactCreation = (contacts || []).reduce(
      (result, item) => {
        if (item.contactType === ContactType.Email) {
          result.hideEmail = !!item.hidden;
        }

        if (item.contactType === ContactType.Github) {
          result.hideGithub = !!item.hidden;
        }

        if (item.contactType === ContactType.Linkedin) {
          result.hideLinkedin = !!item.hidden;
        }

        if (item.contactType === ContactType.Twitter) {
          result.hideTwitter = !!item.hidden;
        }
        return result;
      },
      {
        enable: true,
        hideEmail: true,
        hideGithub: true,
        hideLinkedin: true,
        hideTwitter: true,
      } as ContactCreation,
    );

    return updateBlock({
      handle,
      id: data?.id || '',
      input: {
        index: data?.index || 0,
        displayName: data?.displayName || DEFAULT_BLOCK_TITLE[BlockType.Contact],
        contact: {
          ...currentState,
          ...params,
        },
      },
    });
  };
  const publicContact = () => {
    return isCreate
      ? createBlock({
          profileId,
          handle,
          input: {
            displayName: DEFAULT_BLOCK_TITLE[BlockType.Contact],
            contact: {
              enable: true,
              hideGithub: false,
              hideTwitter: false,
              hideEmail: false,
              hideLinkedin: false,
            },
          },
        })
      : updateVisibility({
          handle,
          id: data?.id || '',
          visibility: BlockVisibility.BlockVisibilityPublic,
        });
  };

  const privateContact = () => {
    return updateVisibility({
      handle,
      id: data?.id || '',
      visibility: BlockVisibility.BlockVisibilityPrivate,
    });
  };

  return (
    <BlockWrapper
      title={data?.displayName || DEFAULT_BLOCK_TITLE[BlockType.Contact]}
      key={data?.id}
      editButton={
        couldEdit ? (
          <EditButton onPrivate={privateContact} onPublic={publicContact} data={data} isCreate={isCreate} />
        ) : null
      }
      contentProps={{ position: 'relative' }}
    >
      {contacts?.map((contact) => (
        <ContactItem
          blockVisibility={data?.visibility}
          isCreate={!!isCreate}
          updateItem={updateItemVisibility}
          isEditing={!!couldEdit}
          key={contact.contactType}
          {...contact}
        />
      ))}

      {shouldCollapsed ? <PrivateMask /> : null}
    </BlockWrapper>
  );
}

export default Contact;
