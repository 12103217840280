import { DEFAULT_BLOCK_TITLE } from '@/configs/profile';
import { useCreateBlocks } from '@/hooks/profile';
import { useCouldEditProfile } from '@/hooks/profile/useCouldEditProfile';
import { useUpdateBlockVisibility } from '@/hooks/profile/useUpdateBlockVisibility';
import {
  BlockType,
  BlockVisibility,
  EventBlock,
  OrgProfile,
  api,
  useGetOrganizationEventsQuery,
} from '@cyber/service/pwa';
import { LoadingButton } from '@cyberlab/uikit';
import { Icon } from '@link3-pwa/icons';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { IBlockProps } from '../../types';
import BlockWrapper from '../core/BlockWrapper';
import EventList from './EventList';

const EditButton = (props: { data?: EventBlock; profileId: string; isCreate?: boolean; handle: string }) => {
  const { handle, data, profileId, isCreate } = props;

  const [isLoading, toggleIsLoading] = useState(false);

  const { couldEdit } = useCouldEditProfile();

  const [updateVisibility] = useUpdateBlockVisibility();
  const [createBlock] = useCreateBlocks();

  const handleDelete = async () => {
    if (!data) return;
    toggleIsLoading(true);
    await updateVisibility({
      handle,
      id: data.id,
      visibility: BlockVisibility.BlockVisibilityPrivate,
    });
    toggleIsLoading(false);
  };

  const handleCreate = async () => {
    if (!data) return;
    toggleIsLoading(true);
    await (isCreate
      ? createBlock({
          handle,
          profileId,
          input: {
            displayName: DEFAULT_BLOCK_TITLE[BlockType.Event],
            event: {
              enable: true,
            },
          },
        })
      : updateVisibility({
          handle,
          id: data.id,
          visibility: BlockVisibility.BlockVisibilityPublic,
        }));
    toggleIsLoading(false);
  };

  if (isCreate || data?.visibility === BlockVisibility.BlockVisibilityPrivate) {
    return (
      <LoadingButton
        loading={isLoading}
        variant='outlined'
        sx={{ px: 1, height: 28, fontSize: 12 }}
        onClick={handleCreate}
        startIcon={<Icon name='icon-eye-off-line' size={18} mt={-0.5} />}
      >
        Private
      </LoadingButton>
    );
  }

  return (
    <LoadingButton
      loading={isLoading}
      variant='outlined'
      sx={{ px: 1, height: 28, fontSize: 12 }}
      startIcon={<Icon name='icon-eye-line' size={18} mt={-0.5} />}
      onClick={handleDelete}
    >
      Public
    </LoadingButton>
  );
};

interface ILink3EventProps extends IBlockProps {
  data: EventBlock;
}
function Link3Event(props: ILink3EventProps) {
  const { data, profileId, isCreate } = props;
  const { couldEdit } = useCouldEditProfile();

  const { query } = useRouter();
  const handle = (query?.handle || '').toString();
  // get orgId
  const { orgId } = api.endpoints.getProfile.useQueryState(
    { handle },
    {
      selectFromResult({ currentData }) {
        return {
          orgId: (currentData?.profile.data as OrgProfile)?.organization.id,
        };
      },
      skip: !handle,
    },
  );

  const { _events } = useGetOrganizationEventsQuery(
    { id: orgId, first: 6 },
    {
      skip: !orgId || !isCreate,
      selectFromResult({ currentData }) {
        return {
          _events: currentData?.organization?.events.list,
        };
      },
    },
  );

  const events = data.events || _events;

  return (
    <BlockWrapper
      key={data.id}
      title={data.displayName || DEFAULT_BLOCK_TITLE[BlockType.Event]}
      editButton={
        couldEdit ? <EditButton data={data} profileId={profileId} isCreate={isCreate} handle={handle} /> : null
      }
      contentProps={{ gap: 3, sx: { p: isCreate ? 0 : undefined } }}
    >
      <EventList events={events || []} />
    </BlockWrapper>
  );
}

export default Link3Event;
