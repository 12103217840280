import Wrapper from '@/component/Wrapper';
import { chainData } from '@/configs/profile';
import { useGetTokenPreviewQuery } from '@cyber/service/api';
import { Avatar, AvatarGroup, Divider, Flex, Text } from '@cyberlab/uikit';
import { IOrgBasicInfoProps } from '../../types';

function SupportChains(props: IOrgBasicInfoProps) {
  const { data } = props;

  const cmcTokenId = data?.cmcTokenId;
  const networks = data?.networks;

  const { token } = useGetTokenPreviewQuery(cmcTokenId!, {
    skip: !cmcTokenId || cmcTokenId === '0',
    selectFromResult({ currentData, ...rest }) {
      return {
        token: currentData?.data,
      };
    },
  });

  return (
    <Wrapper gap={36 / 8}>
      <Flex justifyContent='space-between'>
        <Text variant='body1' color='text.250'>
          Supported Chains
        </Text>

        {networks ? (
          <AvatarGroup avatars={networks.filter((n) => !!n).map((network) => chainData[network]?.icon)} limit={8} />
        ) : (
          '-'
        )}
      </Flex>

      <Flex justifyContent='space-between'>
        <Text variant='body1' color='text.250'>
          Token
        </Text>

        {token?.symbol ? (
          <Flex gap={1} alignItems='center'>
            <Avatar src={token.logo} sx={{ width: 20, height: 20 }} />
            <Text variant='body1' color='text.100'>
              {token.symbol || cmcTokenId}
            </Text>
            <Divider flexItem orientation='vertical' />
            {token.price ? (
              <Text variant='body1' color='brand.main'>
                ~${token.price.toFixed(2)}
              </Text>
            ) : null}
          </Flex>
        ) : (
          '-'
        )}
      </Flex>
    </Wrapper>
  );
}

export default SupportChains;
