import { DEFAULT_BLOCK_TITLE } from '@/configs/profile';
import { useCouldEditProfile } from '@/hooks/profile/useCouldEditProfile';
import { BlockType, NftCollectionBlock } from '@cyber/service/pwa';
import dynamic from 'next/dynamic';
import { IBlockProps } from '../../types';
import BlockWrapper from '../core/BlockWrapper';
import EditButton from '../core/EditButton';
import OpenSeaItem from './OpenSeaItem';

const EditModal = dynamic(() => import('./OpenSea.edit'), { ssr: false });

interface IOpenSeaBlockProps extends IBlockProps {
  data: NftCollectionBlock;
}

function OpenSea(props: IOpenSeaBlockProps) {
  const { data, profileId, isCreate } = props;
  const { couldEdit } = useCouldEditProfile();

  return (
    <BlockWrapper
      editButton={
        <EditButton<NftCollectionBlock>
          profileId={profileId}
          data={data}
          hidden={!couldEdit}
          isCreate={isCreate}
          Modal={EditModal}
        />
      }
      title={data?.displayName || DEFAULT_BLOCK_TITLE[BlockType.NftCollection]}
      key={data?.id}
      contentProps={{ sx: { gap: 3, p: isCreate ? 0 : undefined } }}
      noDivider={isCreate}
    >
      {!isCreate ? data.slugs.map((slug) => <OpenSeaItem key={slug} slug={slug} />) : null}
    </BlockWrapper>
  );
}

export default OpenSea;
