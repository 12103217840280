import { getAuthorFromProfile } from '@/utils/getAuthorFromProfile';
import { formatNumber } from '@cyber-co/utils';
import { PostCardFragment } from '@cyber/service/pwa';
import { Flex, SxProps, Text } from '@cyberlab/uikit';
import format from 'date-fns/format';
import { useRouter } from 'next/router';
import OrgRow from '../OrgRow';
import Wrapper from '../Wrapper';

export type PostCardProps = {
  data: PostCardFragment;
  sx?: SxProps;
};
export default function PostCard(props: PostCardProps) {
  const { data, sx } = props;
  const { cover, title, description, publishedAt, profile, readTime, essence, id } = data;
  const router = useRouter();
  return (
    <Wrapper
      gap={1.5}
      onClick={() => {
        router.push(`/post/${id}`);
      }}
      sx={sx}
    >
      {/* header */}
      <Flex gap={1} alignItems='center' justifyContent='space-between'>
        <OrgRow
          orgAvatar={getAuthorFromProfile(profile).picture || ''}
          orgName={getAuthorFromProfile(profile).displayName}
          textColor='text.100'
          handle={getAuthorFromProfile(profile).handle}
        />
        <Text variant='caption' color='text.250'>
          {format(new Date(publishedAt), 'MMM dd, yyyy')}
        </Text>
      </Flex>
      <Text variant='subtitle1' bold>
        {title}
      </Text>
      <Text variant='body2' lines={3}>
        {description}
      </Text>
      <img src={cover} alt='' style={{ width: '100%', display: 'block', borderRadius: 4 }} />
      {/* footer */}
      <Flex justifyContent='space-between' alignItems='center'>
        <Text variant='caption' color='text.250'>
          {readTime} min read
        </Text>
        {essence?.totalSupply && essence?.totalCollected ? (
          <Flex alignItems='center'>
            <Text bold variant='caption'>
              {formatNumber(essence?.totalCollected)}
            </Text>
            <Text variant='caption' color='text.250'>
              {essence?.totalSupply >= 1000000 ? null : <>/{formatNumber(essence?.totalSupply)} </>}
              Collected
            </Text>
          </Flex>
        ) : null}
      </Flex>
    </Wrapper>
  );
}
