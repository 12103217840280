import { Flex, Text } from '@cyberlab/uikit';
import GlobalAvatar from './GlobalAvatar';
import OrgIcon from './Icons/OrgIcon';
import { ProfileType } from '@cyber/service/pwa';
import { useRouter } from 'next/router';
type OrgRowProps = {
  orgAvatar?: string | null;
  orgName?: string;
  avatarSize?: number;
  textColor?: string;
  gap?: number;
  handle?: string;
};
export default function OrgRow({ gap, orgAvatar, orgName, avatarSize, textColor, handle }: OrgRowProps) {
  const router = useRouter();
  return (
    <Flex
      gap={gap || 0.5}
      alignItems='center'
      onClick={(e) => {
        if (!handle) return;
        e.stopPropagation();
        router.push(`/${handle}`);
      }}
    >
      <GlobalAvatar
        src={orgAvatar}
        variant='square'
        type={ProfileType.Organization}
        size={avatarSize || 24}
        sx={{ borderRadius: 1 }}
      />
      <Text variant='body2' color={textColor || 'text.250'}>
        {orgName}
      </Text>
      <OrgIcon size={18} />
    </Flex>
  );
}
