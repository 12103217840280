import { DEFAULT_BLOCK_TITLE } from '@/configs/profile';
import { useCouldEditProfile } from '@/hooks/profile/useCouldEditProfile';
import useModalState from '@/hooks/useModalState';
import { BlockType, WorkExperienceBlock } from '@cyber/service/pwa';
import dynamic from 'next/dynamic';
import { useFormContext } from 'react-hook-form';
import { IBlockProps } from '../../types';
import BlockWrapper from '../core/BlockWrapper';
import EditButton from '../core/EditButton';
import withForm from '../core/withForm';
import { EditorProps, TFormValue } from './Work.edit';
import WorkItem from './WorkItem';

const WorkEditor = dynamic(() => import('./Work.edit'), { ssr: false });

interface IWorkProps extends IBlockProps {
  data: WorkExperienceBlock;
}

function Works(props: IWorkProps) {
  const { data, isCreate, profileId } = props;
  const { couldEdit } = useCouldEditProfile();
  const { setValue } = useFormContext<TFormValue>();

  const { isOpen, closeModal, openModal } = useModalState();

  const handleEditItem = (id: string) => {
    setValue('workExperienceId', id);

    openModal();
  };

  return (
    <BlockWrapper
      title={data?.displayName || DEFAULT_BLOCK_TITLE[BlockType.WorkExperience]}
      key={data.id}
      contentProps={{ gap: 3, sx: { p: isCreate ? 0 : undefined } }}
      editButton={
        <EditButton<WorkExperienceBlock>
          hidden={!couldEdit}
          profileId={profileId}
          isCreate
          Modal={WorkEditor}
          data={data}
          openModal={openModal}
          closeModal={closeModal}
          isModalOpen={isOpen}
        />
      }
      noDivider={isCreate}
    >
      {!isCreate
        ? data?.workExperiences.map((exp) => <WorkItem onEdit={handleEditItem} key={exp.id} data={exp} />)
        : null}
    </BlockWrapper>
  );
}

export default withForm<EditorProps>(Works as any);
