import GlobalAvatar from '@/component/GlobalAvatar';
import OrgIcon from '@/component/Icons/OrgIcon';
import { fixIPFSUrl } from '@cyber-co/utils';
import { useRetriveW3stInfoQuery } from '@cyber/service/api';
import {
  Box,
  ClickAwayListener,
  Flex,
  RatioBox,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from '@cyberlab/uikit';
import { useState } from 'react';
import NFTItem from '../core/NFTItem';

type TW3stMetaData = {
  name: string;
  image: string;
  external_url: string;
  app_id: string;
  version: string;
  time: string;
  issuer: {
    handle: string;
    avatar: string;
    displayName: String;
  };
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    margin: 0,
    padding: 12,
  },
});

function W3stItem(props: { tokenUri: string; noTip?: boolean }) {
  const { tokenUri, noTip = false } = props;
  const [open, setOpen] = useState(false);
  // const [metaData, setMetaData] = useState<TW3stMetaData>();

  const { data: metaData, isFetching } = useRetriveW3stInfoQuery(tokenUri);

  if (isFetching)
    return (
      <RatioBox>
        <Skeleton variant='rounded' width='100%' height='100%' />
      </RatioBox>
    );

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false);
      }}
    >
      <Box>
        <StyledTooltip
          open={open && !noTip}
          title={
            <Stack gap={1.5}>
              <Text bold>{metaData?.name}</Text>
              <Text variant='body2' color='text.250'>
                {metaData?.name}
              </Text>
              <Flex alignItems='center' gap={0.5}>
                <GlobalAvatar src={metaData?.issuer.avatar} variant='square' size={18} />
                <Text variant='body2' color='text.250'>
                  {metaData?.issuer.displayName}
                </Text>
                <OrgIcon size={18} />
              </Flex>
            </Stack>
          }
          placement='top'
        >
          <Box
            onClick={() => {
              setOpen(!open);
            }}
          >
            <NFTItem imageUrl={fixIPFSUrl(metaData?.image)} />
          </Box>
        </StyledTooltip>
      </Box>
    </ClickAwayListener>
  );
}

export default W3stItem;
