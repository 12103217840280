import { useCopyToClipboard } from '@cyber-co/utils';
import { Icon } from '@link3-pwa/icons';
export default function CopyIcon(props: { size?: number; text: string }) {
  const { size = 18, text } = props;
  const [isCopied, copy] = useCopyToClipboard();
  const handleCopy = () => {
    copy(text);
  };
  return (
    <Icon
      name={isCopied ? 'icon-file-copy-success-line' : 'icon-file-copy-line'}
      color={isCopied ? 'success.main' : 'text.100'}
      size={size}
      onClick={handleCopy}
    />
  );
}
