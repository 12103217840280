import { DEFAULT_BLOCK_TITLE } from '@/configs/profile';
import { useCouldEditProfile } from '@/hooks/profile/useCouldEditProfile';
import { BlockType, SuperLinkBlock } from '@cyber/service/pwa';
import { Button } from '@cyberlab/uikit';
import Icon from '@link3-pwa/icons';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import { IBlockProps } from '../../types';
import BlockWrapper from '../core/BlockWrapper';
import EmbeddedBlog from './EmbeddedBlog';

const FeaturedContentsEditModal = dynamic(() => import('./FeaturedContents.edit'), { ssr: false });
interface IFeaturedContentsProps extends IBlockProps {
  data: SuperLinkBlock;
}

function FeaturedContents(props: IFeaturedContentsProps) {
  const { data, profileId, isCreate } = props;

  const { couldEdit } = useCouldEditProfile();
  const [editingIndex, setEditingIndex] = useState(-1);

  const closeModal = () => setEditingIndex(-1);

  const addLink = () => {
    setEditingIndex(data.superLinks?.length || 0);
  };

  return (
    <BlockWrapper
      editButton={
        couldEdit ? (
          <Button
            {...props}
            size='small'
            variant='outlined'
            color='primary'
            sx={{ height: 28, px: 1, flexShrink: 0 }}
            startIcon={<Icon size={18} name='icon-add-circle-line' mb={0.25} />}
            onClick={addLink}
          >
            Add
          </Button>
        ) : null
      }
      title={data.displayName || DEFAULT_BLOCK_TITLE[BlockType.Superlink]}
      key={data.id}
      contentProps={{ sx: { gap: 3, p: isCreate ? 0 : undefined } }}
      noDivider={isCreate}
    >
      {!isCreate
        ? data.superLinks.map((superLink, index) => (
            <EmbeddedBlog
              couldEdit={!!couldEdit}
              key={superLink.link}
              data={superLink}
              onEdit={() => setEditingIndex(index)}
            />
          ))
        : null}

      {couldEdit ? (
        <FeaturedContentsEditModal
          editingIndex={editingIndex}
          onClose={closeModal}
          open={editingIndex > -1}
          profileId={profileId}
          blockData={data}
        />
      ) : null}
    </BlockWrapper>
  );
}

export default FeaturedContents;
