import { DEFAULT_BLOCK_TITLE } from '@/configs/profile';
import { useCouldEditProfile } from '@/hooks/profile/useCouldEditProfile';
import { BlockType, W3StBlock } from '@cyber/service/pwa';
import dynamic from 'next/dynamic';
import { IBlockProps } from '../../types';
import BlockWrapper from '../core/BlockWrapper';
import EditButton from '../core/EditButton';
import NFTGrid from '../core/NFTGrid';
import W3stItem from './W3stItem';

const W3stEditModal = dynamic(() => import('./W3st.edit'), { ssr: false });

interface IW3stProps extends IBlockProps {
  data: W3StBlock;
}
function W3st(props: IW3stProps) {
  const { data, profileId, isCreate } = props;
  const { couldEdit } = useCouldEditProfile();

  return (
    <BlockWrapper
      key={data.id}
      title={data.displayName || DEFAULT_BLOCK_TITLE[BlockType.W3StGallery]}
      editButton={
        <EditButton<W3StBlock>
          hidden={!couldEdit}
          data={data}
          profileId={profileId}
          isCreate={isCreate}
          Modal={W3stEditModal}
        />
      }
      noDivider={isCreate}
      contentProps={{ sx: { p: isCreate ? 0 : undefined } }}
    >
      {!isCreate ? (
        <NFTGrid itemLength={data.tokenUris.length}>
          {data.tokenUris.map((tokenUri) => (
            <W3stItem key={tokenUri} tokenUri={tokenUri} />
          ))}
        </NFTGrid>
      ) : null}
    </BlockWrapper>
  );
}

export default W3st;
