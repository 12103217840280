import { Maybe } from '@cyber/service/pwa';
import { Flex, Text } from '@cyberlab/uikit';
import { Icon } from '@link3-pwa/icons';
import Link from 'next/link';

export default function CreateAndVerifyButton({
  hasHeadline = false,
  profileId,
}: {
  hasHeadline?: boolean;
  profileId?: Maybe<string>;
}) {
  if (hasHeadline) {
    return null;
  }

  return (
    <Link href={`/${profileId}/add-title`}>
      <Flex gap={0.5} px={1} py={0.5} border='1px dashed' borderColor='secondary' borderRadius={1} alignItems='center'>
        <Icon name='icon-add-circle-line' size={18} />
        <Text variant='caption' bold color='primary'>
          Create and verify your title
        </Text>
      </Flex>
    </Link>
  );
}
