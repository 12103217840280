import { DEFAULT_BLOCK_TITLE } from '@/configs/profile';
import { useCouldEditProfile } from '@/hooks/profile';
import { BlockType, NftBlock } from '@cyber/service/pwa';
import { IBlockProps } from '../../types';
import BlockWrapper from '../core/BlockWrapper';
import EditButton from '../core/EditButton';
import NFTGrid from '../core/NFTGrid';
import NFTItem from '../core/NFTItem';
import NFTsEditModal from './NFTs.edit';

interface INFTProps extends IBlockProps {
  data: NftBlock;
}
function NFT(props: INFTProps) {
  const { data, profileId, isCreate } = props;
  const { couldEdit } = useCouldEditProfile();

  return (
    <BlockWrapper
      editButton={
        <EditButton<NftBlock>
          hidden={!couldEdit}
          data={data}
          profileId={profileId}
          isCreate={isCreate}
          Modal={NFTsEditModal}
        />
      }
      key={data.id}
      title={data.displayName || DEFAULT_BLOCK_TITLE[BlockType.NftGallery]}
      contentProps={{ sx: { p: isCreate ? 0 : undefined } }}
      noDivider={isCreate}
    >
      {!isCreate ? (
        <NFTGrid itemLength={data.nfts?.length}>
          {data.nfts?.map((nft, i) => <NFTItem key={nft.tokenId + i} imageUrl={nft.imageUrl} name={nft.name} />)}
        </NFTGrid>
      ) : null}
    </BlockWrapper>
  );
}

export default NFT;
