import Wrapper from '@/component/Wrapper';
import { PerProfile } from '@cyber/service/pwa';
import { AvatarGroup, Text } from '@cyberlab/uikit';

function Connections(props: { connections?: PerProfile[]; total: number }) {
  const { connections = [], total } = props;

  return (
    <Wrapper py={1.5} gap={1.5} alignItems='center' justifyContent='start' flexDirection='row'>
      <AvatarGroup avatars={connections.map((c) => c.profilePicture.picture || '')} limit={5} />
      <Text variant='caption' color='text.100' textAlign='left'>
        {connections
          .slice(0, 5)
          .map((c) => c.displayName.displayName)
          .join(', ')}

        {total > 5
          ? ` and ${total - 5} others are mutual connections`
          : ` ${total < 2 ? 'is' : 'are'} mutual connection${total < 2 ? '' : 's'}`}
      </Text>
    </Wrapper>
  );
}

export default Connections;
