import { styled } from '@cyberlab/uikit';
const Container = styled('a')(({ theme: { spacing, palette, shape } }) => ({
  display: 'flex',
  textDecoration: 'none',
  gap: spacing(3),
  padding: spacing(3),
  border: '1px solid',
  borderColor: palette.border?.[100],
  borderRadius: shape.borderRadius,
  position: 'relative',
}));

export default Container;
