import { ConfirmModal, LoadingButton, LoadingButtonProps, Text } from '@cyberlab/uikit';
import Icon from '@link3-pwa/icons';
import { useState } from 'react';

interface IDeleteSectionButtonProps extends LoadingButtonProps {
  onDelete: () => Promise<void>;
  title?: string;
  desc?: string;
}

export default function DeleteSectionButton(props: IDeleteSectionButtonProps) {
  const [isOpen, toggleIsOpen] = useState(false);

  const {
    onDelete,
    title = 'Delete this Section',
    desc = 'Are you sure you want to delete this section?',
    ...buttonProps
  } = props;

  const openConfirmModal = () => {
    toggleIsOpen(true);
  };

  const closeConfirmModal = () => {
    toggleIsOpen(false);
  };

  return (
    <>
      <LoadingButton
        startIcon={<Icon name='icon-delete-bin-line' color='error.main' size={24} />}
        sx={{ bgcolor: 'bg.0', mt: 1, borderRadius: 0, height: 48, flexShrink: 0 }}
        color='error'
        {...buttonProps}
        onClick={openConfirmModal}
      >
        {buttonProps.children}
      </LoadingButton>

      <ConfirmModal onConfirm={onDelete} title={title} open={isOpen} onClose={closeConfirmModal}>
        <Text variant='body2' color='text.250'>
          {desc}
        </Text>
      </ConfirmModal>
    </>
  );
}
