import { Proposal as TProposal, useGetProposalsInSpaceQuery } from '@cyber/service/snapshot';
import Proposal from './Proposal';

function SnapshotContent(props: { spaceId: string }) {
  const { spaceId } = props;
  const { proposals, isFetching } = useGetProposalsInSpaceQuery(
    { spaceId },
    {
      skip: !spaceId,
      selectFromResult({ data, ...rest }) {
        return {
          proposals: (data?.proposals || []) as TProposal[],
          ...rest,
        };
      },
    },
  );

  return (
    <>
      {proposals.map((proposal) => (
        <Proposal key={proposal.id} data={proposal} spaceId={spaceId} />
      ))}
    </>
  );
}

export default SnapshotContent;
