import { useAddresses } from '@/hooks';
import api, { TNFTCollection, TNFTContent } from '@cyber/service/api';
import { ErcType, NftFragment } from '@cyber/service/pwa';
import { Button, Stack, Text, toast } from '@cyberlab/uikit';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import getUniqueNFTId from '../getUniqueNFTId';
import NftCollection from './NftCollection';
import SelectableNFTItem from './SelectableNFTItem';

const NFT_LIMIT = 50;

function Content(props: { data: NftFragment[] }) {
  const { data } = props;
  const { eoa, assetWallets } = useAddresses();
  const { push } = useRouter();

  const nfts: TNFTCollection[] =
    useSelector(api.endpoints.retriveAllNFTByAddress.select(assetWallets.join(',')))?.data?.nfts || [];
  const { control, handleSubmit } = useFormContext();

  const {
    field: { onChange, value: selected },
    fieldState: { error },
  } = useController({
    name: 'nft.nfts',
    defaultValue: data,
    control,
    rules: {
      validate: {
        validateSelected(value: any) {
          return (value && value.length > 0) || 'should select al least 1 NFT item';
        },
      },
    },
  });

  const [selectedNFTs, setSelectedNFTs] = useState<NftFragment[]>(selected || []);

  const selectedNFTIds = (selected || []).map(getUniqueNFTId);

  const appendItem = (nft: NftFragment) => {
    const newNfts = [...selectedNFTs, nft];
    setSelectedNFTs(newNfts);
    onChange && onChange(newNfts);
  };

  const removeItem = (id: string) => {
    const newNfts = selectedNFTs.filter((item) => getUniqueNFTId(item) != id);

    setSelectedNFTs(newNfts);
    onChange && onChange(newNfts);
  };

  const handleSelect = (nft: TNFTContent) => {
    const value: NftFragment = {
      tokenId: nft.token_id,
      imageUrl: nft.image_uri,
      name: nft.name,
      ercType: nft.erc_type as ErcType,
      contract: nft.contract_address,
      owner: nft.owner,
    };

    if (selectedNFTIds.includes(getUniqueNFTId(nft))) return removeItem(getUniqueNFTId(nft));
    if (selectedNFTIds.length >= NFT_LIMIT) return;

    appendItem(value);
  };

  useEffect(() => {
    if (error?.message) {
      toast.error(error.message, { autoClose: 2000 });
    }
  }, [error]);

  const renderNFTs = (NFTs: TNFTContent[]) =>
    NFTs.map((nft) => (
      <SelectableNFTItem
        name={nft.name}
        imageUrl={nft.image_uri}
        onSelect={() => handleSelect(nft)}
        isSelected={selectedNFTIds.includes(getUniqueNFTId(nft))}
        sequence={selectedNFTIds.indexOf(getUniqueNFTId(nft)) + 1}
      />
    ));

  if (nfts.length === 0) {
    return (
      <Stack height='80vh' alignItems='center' justifyContent='center'>
        <Image src='/assets/bg/nft-nodata.svg' width={256} height={170} />

        <Text my={1.5} bold textAlign='center'>
          Nothing here
        </Text>
        <Text textAlign='center' mb={5.5} variant='body2' color='text.250'>
          You can add digital assets from another wallet
        </Text>

        <Button sx={{ width: 190 }} variant='outlined' color='primary' onClick={() => push('/me/cyber-account')}>
          Bundle Wallet
        </Button>
      </Stack>
    );
  }

  return (
    <Stack mt={1} gap={1}>
      {nfts.map((collection, i) => (
        <NftCollection key={collection.contract_address + i} renderNFTs={renderNFTs} collection={collection} />
      ))}
    </Stack>
  );
}

export default Content;
