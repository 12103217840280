import Wrapper from '@/component/Wrapper';
import { Box, RatioBox, Skeleton, Stack, Text } from '@cyberlab/uikit';

function BasicInfoSkeleton() {
  return (
    <Stack>
      <RatioBox ratio={145 / 390} sx={{ position: 'relative' }}>
        <Box bgcolor='background.200' width='100%' height='100%' />
      </RatioBox>
      <Wrapper>
        <Skeleton variant='circular' sx={{ width: 84, height: 84, mt: '-64px' }} />
        <Skeleton>
          <Text variant='h1'>asdasdsadklsajdlaskd</Text>
        </Skeleton>

        <Skeleton>
          <Text variant='body2'>asdasdsadklsajdlaskd</Text>
        </Skeleton>

        <Skeleton sx={{ mt: 2 }}>
          <Text variant='body2'>Full-Stack Developer upgrading frr</Text>
        </Skeleton>

        <Skeleton sx={{ mt: 2 }}>
          <Text variant='caption'>Full-Stack Developer upgrading frr</Text>
        </Skeleton>
      </Wrapper>
    </Stack>
  );
}

export default BasicInfoSkeleton;
