import { DEFAULT_BLOCK_TITLE } from '@/configs/profile';
import { useAddresses } from '@/hooks';
import { useCouldEditProfile } from '@/hooks/profile';
import { getProfileQueryParams } from '@cyber-co/utils';
import {
  BlockType,
  ConnectStatus,
  PerProfile,
  RecommendationBlock,
  useLazyConnectStatusQuery,
} from '@cyber/service/pwa';
import { LoadingButton, Tab, Tabs, toast } from '@cyberlab/uikit';
import { Icon } from '@link3-pwa/icons';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import { IBlockProps } from '../../types';
import BlockWrapper from '../core/BlockWrapper';
import RecommendationList from './RecommendationList';

const RecommendationEditor = dynamic(() => import('./Recommendation.edit/Create'), { ssr: false });
const RecommendationListEditor = dynamic(() => import('./Recommendation.edit/List'), { ssr: false });

interface IRecommendationProps extends IBlockProps {
  data?: RecommendationBlock;
}

function Recommendation(props: IRecommendationProps) {
  const { data, profileId, isCreate } = props;

  const { eoa } = useAddresses();

  const { couldEdit, isPreview } = useCouldEditProfile();
  const [currentTab, setCurrentTab] = useState('1');

  const [isModalOpen, toggleIsModal] = useState(false);
  const [isListModalOpen, toggleIsListModal] = useState(false);

  const [queryConnectStatus, { isFetching: isChecking }] = useLazyConnectStatusQuery();

  const openModal = () => toggleIsModal(true);
  const closeModal = () => toggleIsModal(false);

  const openListModal = () => toggleIsListModal(true);
  const closeListModal = () => toggleIsListModal(false);

  const handleGiveRecommendation = async () => {
    const connected = await queryConnectStatus(getProfileQueryParams(profileId)).then(
      (res) => (res.data?.profile.data as PerProfile)?.connectStatus === ConnectStatus.Connected,
    );

    if (!connected) {
      toast.error('You need to establish a connection with this person before writing a recommendation!');
      return;
    }

    openModal();
  };

  const recommendationData = currentTab === '2' ? data?.given : data?.received;
  const myRecommendationIndex =
    recommendationData?.findIndex((rec) => {
      const [start, end] = rec.user.lightInfo.formattedAddress.split('...');

      return eoa.startsWith(start) && eoa.endsWith(end);
    }) ?? -1;

  const hasGivenRecommendation = myRecommendationIndex > -1;

  return (
    <BlockWrapper
      title={DEFAULT_BLOCK_TITLE[BlockType.Recommendation]}
      contentProps={{ gap: 4 }}
      subtitle={
        <Tabs sx={{ mt: -3, padding: '8px 16px 0 16px' }} value={currentTab} onChange={(_, v) => setCurrentTab(v)}>
          <Tab label='Received' value='1' />
          <Tab label='Given' value='2' />
        </Tabs>
      }
      editButton={
        isPreview ? null : (
          <Icon name={couldEdit ? 'icon-edit-line' : 'icon-arrow-tiny-right'} size={24} onClick={openListModal} />
        )
      }
    >
      <RecommendationList data={recommendationData} />

      {couldEdit || currentTab === '2' || hasGivenRecommendation ? null : (
        <>
          <LoadingButton
            loading={isChecking}
            disabled={isPreview}
            variant='outlined'
            color='primary'
            onClick={handleGiveRecommendation}
          >
            Give your recommendation
          </LoadingButton>
        </>
      )}

      <RecommendationListEditor
        onGiveRecommendation={!couldEdit && !hasGivenRecommendation ? handleGiveRecommendation : undefined}
        open={isListModalOpen}
        onClose={closeListModal}
        profileId={profileId}
        blockData={data}
        couldEdit={couldEdit}
        myCommentIndex={myRecommendationIndex}
        isCreate={!!isCreate}
      />

      <RecommendationEditor open={isModalOpen} onClose={closeModal} profileId={profileId} blockData={data} />
    </BlockWrapper>
  );
}

export default Recommendation;
