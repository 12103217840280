import PostCard from '@/component/PostCard';
import { Post } from '@cyber/service/pwa';
import { Box, Divider, Text } from '@cyberlab/uikit';
import { useState } from 'react';
import ShowMore from '../core/ShowMore';

function PostList(props: { posts: Post[] }) {
  const { posts } = props;

  const hasMore = posts.length > 3;
  const [showMore, toggleShowMore] = useState(false);

  if (posts.length < 1) {
    return (
      <Text my={5} textAlign='center' color='text.250'>
        No Posts
      </Text>
    );
  }

  return (
    <Box>
      {posts.slice(0, showMore ? posts.length : 3).map((post) => (
        <>
          <PostCard key={post.id} data={post as any} />
          <Divider sx={{ '&:last-of-type': { display: 'none' }, mx: 3 }} />
        </>
      ))}

      {hasMore ? (
        <ShowMore
          wrapperProps={{ mt: 0, mb: 3 }}
          isShowMore={showMore}
          setShowMore={() => toggleShowMore((state) => !state)}
        />
      ) : null}
    </Box>
  );
}

export default PostList;
