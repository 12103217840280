import { Box, Stack, Text, Radio } from '@cyberlab/uikit';
import NFTItem from '../../core/NFTItem';

interface ISelectableNFTItemProps {
  imageUrl?: string;
  name?: string;
  isSelected?: boolean;
  onSelect: () => void;
  sequence?: number;
}

const SelectedCover = (props: Pick<ISelectableNFTItemProps, 'sequence' | 'isSelected'>) => {
  const { isSelected, sequence } = props;
  if (!isSelected) return null;

  return (
    <Box position='absolute' top={0} left={0} width='100%' height='100%' zIndex={2} bgcolor='rgba(0,0,0,0.5)'>
      <Text color='text.100' variant='caption' bold position='absolute' top={4} left={4}>
        {sequence}
      </Text>
      <Radio checked={isSelected} position='absolute' top={4} right={4} />
    </Box>
  );
};

function SelectableNFTItem(props: ISelectableNFTItemProps) {
  const { imageUrl, name, onSelect, isSelected, sequence } = props;

  return (
    <Stack gap={1.5}>
      <Box position='relative' onClick={onSelect}>
        <NFTItem imageUrl={imageUrl} />

        <SelectedCover sequence={sequence} isSelected={isSelected} />
      </Box>

      {name ? (
        <Text variant='body2' bold color='text.100'>
          {name}
        </Text>
      ) : null}
    </Stack>
  );
}

export default SelectableNFTItem;
