import GlobalAvatar from '@/component/GlobalAvatar';
import Headline from '@/component/Headline';
import Wrapper from '@/component/Wrapper';
import { useCouldEditProfile } from '@/hooks/profile/useCouldEditProfile';
import useEas from '@/hooks/profile/useEas';
import { fixNFTIPFSURL } from '@cyber-co/utils';
import { PerProfile, Twitter } from '@cyber/service/pwa';
import { Flex, Text } from '@cyberlab/uikit';
import { Box, Stack } from '@mui/material';
import dynamic from 'next/dynamic';
import EASVerification from '../../EAS';
import { IPersonalBasicInfoProps } from '../../types';
import Connections from '../Connections';
import ProfileBackground from '../core/ProfileBackground';
const EditPersonalBasicInfo = dynamic(() => import('./PersonalBasicInfo.edit'), { ssr: false });

const formatHeadline = (data?: Twitter) => {
  if (!data) return data;

  const { profileId, ...result } = data;

  return result as Twitter;
};
function PersonalBasicInfo(props: IPersonalBasicInfoProps) {
  const { data } = props;
  const { couldEdit, isPreview } = useCouldEditProfile();

  const { isVerified, couldClaim } = useEas(data?.id);

  return (
    <>
      <Box>
        <ProfileBackground
          type={data?.personalBackgroundPicture?.type}
          src={fixNFTIPFSURL(data?.personalBackgroundPicture?.picture || '')}
          nfts={data?.personalBackgroundPicture?.nfts}
        />
        <Wrapper gap={3} position='relative'>
          <GlobalAvatar
            bordered
            sx={{ mt: -8 }}
            size={83}
            src={data?.personalProfilePicture?.picture}
            borderWidth={3}
          />

          <Stack gap={1} mt={-1}>
            <Flex justifyContent='space-between' alignItems='center'>
              <Text variant='h1' color='text.100' bold>
                {data?.personalDisplayName?.displayName}
              </Text>

              {couldEdit ? <EditPersonalBasicInfo data={data} /> : null}
            </Flex>

            <Headline
              data={data?.headline}
              isVerified={isVerified}
              couldClaim={couldClaim}
              profileId={data?.id}
              showVerify={couldEdit}
              verifyIcon={
                <EASVerification
                  type='member'
                  orgName={data?.headline?.displayName || data?.headline?.twitterHandle || ''}
                  profileId={data?.id || ''}
                />
              }
            />
          </Stack>

          <Text variant='body2' color='text.250'>
            {data?.bio}
          </Text>

          {data?.mutualConnectionCount && !couldEdit && !isPreview ? (
            <Text variant='body2' color='text.100'>
              {data?.mutualConnectionCount}
              <Text variant='body2' display='inline' color='text.250' ml={0.5}>
                Connection{data.mutualConnectionCount > 1 ? 's' : ''}
              </Text>
            </Text>
          ) : null}
        </Wrapper>
      </Box>

      {data?.mutualConnectionCount && !couldEdit && !isPreview ? (
        <Connections connections={data?.mutualConnections?.list as PerProfile[]} total={data.mutualConnectionCount} />
      ) : null}
    </>
  );
}

export default PersonalBasicInfo;
