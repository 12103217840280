import { formatNumber } from '@cyber-co/utils';
import { useGetCollectionBySlugQuery } from '@cyber/service/api';
import { Avatar, Flex, Skeleton, Stack, SxProps, Text, styled } from '@cyberlab/uikit';

const getOSCollectionLink = (slug?: string) => (slug ? `https://opensea.io/collection/${slug}` : '');

const Container = styled(Stack)(({ theme: { palette, shape, spacing } }) => ({
  border: `1px solid ${palette.bg?.[100]}`,
  borderRadius: shape.borderRadius,
  padding: spacing(1.5),
  gap: spacing(3),
}));

const CollectionStatsItem = (props: {
  isLoading?: boolean;
  symbol?: string;
  number: number | string;
  title: string;
  sx?: SxProps;
}) => {
  const { isLoading, symbol, number, title, sx } = props;

  return (
    <Stack
      width='25%'
      flexShrink={1}
      gap={0.5}
      sx={{
        '&:not(&:last-of-type)': {
          borderRight: '1px solid',
          borderColor: 'border.100',
        },
        ...sx,
      }}
    >
      {isLoading ? (
        <Skeleton>
          <Text bold variant='body2' color='#fff'>
            Link3
          </Text>
        </Skeleton>
      ) : (
        <Text bold variant='body2' color='#fff'>
          {number} {symbol}
        </Text>
      )}

      <Text variant='caption' color='text.250'>
        {title}
      </Text>
    </Stack>
  );
};

function OpenSeaItem(props: { slug: string }) {
  const { slug } = props;

  const { data, isFetching } = useGetCollectionBySlugQuery(slug, {
    skip: !slug,
    selectFromResult({ data, isFetching }) {
      return {
        data: data || {},
        isFetching,
      };
    },
  });

  return (
    <Container onClick={() => window.open(getOSCollectionLink(slug))}>
      <Flex gap={1.5} alignItems='center'>
        <Avatar sx={{ width: 64, height: 64 }} variant='rounded' src={data?.cover} />

        <Stack gap={0.5} justifyContent='space-between'>
          <Text bold variant='body1' color='#fff'>
            {data?.name}
          </Text>

          <Text lines={3} variant='body2' color='text.250'>
            {data?.description}
          </Text>
        </Stack>
      </Flex>

      <Flex gap={0.5} mt={2} flexWrap='nowrap'>
        <CollectionStatsItem
          isLoading={isFetching}
          symbol={data?.paymentTokens}
          number={data.stats?.floor}
          title='Floor'
        />
        <CollectionStatsItem
          isLoading={isFetching}
          symbol={data.paymentTokens}
          number={formatNumber(Number(Number(data.stats?.totalVolume).toFixed(0)))}
          title='Total Volume'
        />
        <CollectionStatsItem isLoading={isFetching} number={formatNumber(data.stats?.itemCount)} title='Items' />
        <CollectionStatsItem isLoading={isFetching} number={formatNumber(data.stats?.ownersCount)} title='Owners' />
      </Flex>
    </Container>
  );
}

export default OpenSeaItem;
