import { Text, alpha, useTheme } from '@cyberlab/uikit';

function CredItem({
  name,
  index,
  isActive = true,
  onClick,
}: {
  name: string;
  index: number;
  isActive?: boolean;
  onClick?: () => void;
}) {
  const { palette } = useTheme();

  const colorMap = [
    palette.brand.main,
    palette.twitter,
    palette.warning.main,
    palette.error.main,
    palette.success.main,
    '#9858FF',
  ];
  const primaryColor = colorMap[index % colorMap.length];
  const secondaryColor = alpha(colorMap[index % colorMap.length], 0.1);
  return (
    <Text
      onClick={onClick}
      variant='caption'
      bold
      sx={{
        border: '1px solid',
        borderColor: primaryColor,
        backgroundColor: secondaryColor,
        color: primaryColor,
        borderRadius: 1,
        px: 1.5,
        py: 0.5,
        opacity: isActive ? 1 : 0.5,
        transition: 'opacity 0.3s',
      }}
    >
      {name}
    </Text>
  );
}

export default CredItem;
