import { Stack } from '@cyberlab/uikit';
import BasicInfoSkeleton from './BasicInfo.skeleton';
import BlockSkeleton from './Block.skeleton';

function ProfileSkeleton() {
  return (
    <Stack gap={1}>
      <BasicInfoSkeleton />

      <BlockSkeleton />
      <BlockSkeleton />
      <BlockSkeleton />
    </Stack>
  );
}

export default ProfileSkeleton;
