import { DEFAULT_BLOCK_TITLE } from '@/configs/profile';
import { useCouldEditProfile } from '@/hooks/profile/useCouldEditProfile';
import { BlockType, TwitterBlock } from '@cyber/service/pwa';
import dynamic from 'next/dynamic';
import { IBlockProps } from '../../types';
import BlockWrapper from '../core/BlockWrapper';
import EditButton from '../core/EditButton';
import NFTGrid from '../core/NFTGrid';
import TwitterItem from './TwitterItem';

const TwitterEdit = dynamic(() => import('./Twitter.edit'), { ssr: false });

interface ITwitterProps extends IBlockProps {
  data: TwitterBlock;
}

function Twitter(props: ITwitterProps) {
  const { data, profileId, isCreate } = props;

  const { couldEdit } = useCouldEditProfile();

  return (
    <BlockWrapper
      title={data.displayName || DEFAULT_BLOCK_TITLE[BlockType.Twitter]}
      key={data.id}
      contentProps={{ gap: 4, sx: { p: isCreate ? 0 : undefined } }}
      editButton={
        <EditButton<TwitterBlock>
          hidden={!couldEdit}
          data={data}
          profileId={profileId}
          isCreate={isCreate}
          Modal={TwitterEdit}
        />
      }
      noDivider={isCreate}
    >
      {!isCreate ? (
        <NFTGrid itemLength={data.twitters.length > 6 ? data.twitters.length : 6}>
          {data.twitters.map((tw) => (
            <TwitterItem key={tw.profileId} data={tw} />
          ))}
        </NFTGrid>
      ) : null}
    </BlockWrapper>
  );
}

export default Twitter;
