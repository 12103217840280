import { fixTwitterLink, getProfileRouter } from '@cyber-co/utils';
import { Twitter } from '@cyber/service/pwa';
import { Avatar, Stack, Text } from '@cyberlab/uikit';
import Link from 'next/link';

function TwitterItem(props: { data: Twitter }) {
  const { data } = props;

  const getLink = () => {
    if (data.profileId) {
      return getProfileRouter(data.profileId);
    } else {
      return fixTwitterLink({ id: data.twitterId });
    }
  };

  return (
    <Link href={getLink()}>
      <Stack gap={1} alignItems='center' width='100%' overflow='hidden'>
        <Avatar sx={{ width: 40, height: 40, flexShrink: 0 }} src={data.avatar} />

        <Text noWrap variant='body1' color='text.100' width='100%' textAlign='center'>
          {data.displayName}
        </Text>

        <Text noWrap variant='body2' color='text.250' mt={-0.5} width='100%' textAlign='center'>
          {data.title}
        </Text>
      </Stack>
    </Link>
  );
}

export default TwitterItem;
