import { BlockVisibility, Contact, ContactCreation, ContactType } from '@cyber/service/pwa';
import { Button, CircularProgress, Flex, Stack, Text } from '@cyberlab/uikit';
import { Icon } from '@link3-pwa/icons';
import { useState } from 'react';
import useSocialMediaInfo from './useSocialMediaInfo';

interface IContactItemProps extends Contact {
  isEditing: boolean;
  isCreate: boolean;
  updateItem: (params: Partial<ContactCreation>) => Promise<any>;
  blockVisibility?: BlockVisibility;
}

const TITLE = {
  [ContactType.Email]: 'Email',
  [ContactType.Linkedin]: 'LinkedIn',
  [ContactType.Twitter]: 'Twitter',
  [ContactType.Github]: 'Github',
};

const NO_DATA = {
  [ContactType.Email]: 'Link your Email',
  [ContactType.Linkedin]: 'Link your LinkedIn account',
  [ContactType.Twitter]: 'Link your Twitter account',
  [ContactType.Github]: 'Link your Github account',
};

const ITEMKEY: { [key in ContactType]: keyof ContactCreation } = {
  [ContactType.Email]: 'hideEmail',
  [ContactType.Linkedin]: 'hideLinkedin',
  [ContactType.Twitter]: 'hideTwitter',
  [ContactType.Github]: 'hideGithub',
};

function ContactItem(props: IContactItemProps) {
  const { contactType, name, hidden, isEditing, isCreate, updateItem, blockVisibility } = props;

  const info = useSocialMediaInfo({
    name,
    contactType,
    hidden: false,
  });

  const [isLoading, toggleIsLoading] = useState(false);

  const handleChangeVisibility = async (value: boolean) => {
    toggleIsLoading(true);
    await updateItem({
      [ITEMKEY[contactType]]: value,
    });
    toggleIsLoading(false);
  };

  const renderAction = () => {
    if (!name)
      return (
        <Button onClick={info.link} size='tiny' color='primary' variant='contained'>
          Link
        </Button>
      );

    if (isCreate)
      return (
        <Flex alignItems='center' gap={0.5}>
          <Text color='text.250' variant='body2'>
            Private
          </Text>

          <Icon name='icon-eye-off-line' />
        </Flex>
      );

    if (isLoading) {
      return <CircularProgress size={24} />;
    }

    if (hidden || blockVisibility === BlockVisibility.BlockVisibilityPrivate) {
      return (
        <Flex alignItems='center' gap={0.5} onClick={() => handleChangeVisibility(false)}>
          <Text color='text.250' variant='body2'>
            Private
          </Text>

          <Icon name='icon-eye-off-line' />
        </Flex>
      );
    } else {
      return (
        <Flex alignItems='center' gap={0.5} onClick={() => handleChangeVisibility(true)}>
          <Text color='text.250' variant='body2'>
            Public
          </Text>

          <Icon name='icon-eye-line' />
        </Flex>
      );
    }
  };

  return (
    <Flex alignItems='center' justifyContent='space-between' py={2}>
      <Stack
        gap={0.5}
        onClick={() => info.url && window.open(info.url)}
        sx={{ opacity: hidden && info.name ? 0.2 : 1, transition: 'opacity 0.1s' }}
      >
        <Text variant='body1'>{TITLE[contactType]}</Text>
        <Text variant='body2' color='text.250'>
          {info.handle || info.name || NO_DATA[contactType]}
        </Text>
      </Stack>

      {isEditing ? renderAction() : info.url ? <Icon name='icon-arrow-tiny-right' /> : null}
    </Flex>
  );
}

export default ContactItem;
