import { DEFAULT_BLOCK_TITLE } from '@/configs/profile';
import { useCouldEditProfile } from '@/hooks/profile/useCouldEditProfile';
import { BlockType, Skill, SkillBlock } from '@cyber/service/pwa';
import { Icon } from '@link3-pwa/icons';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import { IBlockProps } from '../../types';
import BlockWrapper from '../core/BlockWrapper';
import EditButton from '../core/EditButton';
import SkillTag from './SkillTag';

const SkillsEditor = dynamic(() => import('./Skills.edit'), { ssr: false });
const SkillListModal = dynamic(() => import('./Skills.view/List'), { ssr: false });
const SkillDetailModal = dynamic(() => import('./Skills.view/Detail'), { ssr: false });

interface ISkillProps extends IBlockProps {
  data?: SkillBlock;
}

function Skills(props: ISkillProps) {
  const { isCreate, profileId, data } = props;

  const { couldEdit } = useCouldEditProfile();
  const [isListModalOpen, toggleIsListModalOpen] = useState(false);

  const [selectedSkill, setSelectedSkill] = useState<Skill>();

  const openListModal = () => toggleIsListModalOpen(true);
  const closeListModal = () => toggleIsListModalOpen(false);
  const openDetailModal = (skill: Skill) => setSelectedSkill(skill);
  const closeDetailModal = () => setSelectedSkill(undefined);

  return (
    <BlockWrapper
      editButton={
        couldEdit ? (
          <EditButton<SkillBlock>
            data={data}
            hidden={!couldEdit}
            isCreate={isCreate}
            profileId={profileId}
            Modal={SkillsEditor}
          />
        ) : (
          <Icon name={'icon-arrow-tiny-right'} size={24} onClick={openListModal} />
        )
      }
      title={DEFAULT_BLOCK_TITLE[BlockType.Skill]}
      contentProps={{ flexDirection: 'row', gap: 1.5, flexWrap: 'wrap', sx: { p: isCreate ? 0 : null } }}
      noDivider={isCreate}
    >
      {!isCreate
        ? data?.skills.map((skill) => <SkillTag key={skill.id} data={skill} onClick={() => openDetailModal(skill)} />)
        : null}

      <SkillListModal
        profileId={profileId}
        open={isListModalOpen}
        blockData={data}
        onClose={closeListModal}
        onSkillTagClick={openDetailModal}
      />

      <SkillDetailModal profileId={profileId} open={!!selectedSkill} data={selectedSkill} onClose={closeDetailModal} />
    </BlockWrapper>
  );
}

export default Skills;
