import { TEditModalProps } from '@/component/Profile/types';
import { DEFAULT_BLOCK_TITLE } from '@/configs/profile';
import { BlockType, NftBlock } from '@cyber/service/pwa';
import ProfileFormDrawer from '../../core/ProfileForm.drawer';
import withForm from '../../core/withForm';
import Content from './Content';

interface INFTsEditModalProps extends TEditModalProps {
  blockData?: NftBlock;
}
function NFTsEditModal(props: INFTsEditModalProps) {
  const { blockData } = props;
  return (
    <ProfileFormDrawer
      title={blockData?.displayName || DEFAULT_BLOCK_TITLE[BlockType.NftGallery]}
      {...props}
      blockData={blockData}
    >
      <Content data={blockData?.nfts || []} />
    </ProfileFormDrawer>
  );
}

export default withForm<INFTsEditModalProps>(NFTsEditModal);
