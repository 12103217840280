import { revalidateSSGPage } from '@/utils';
import { getProfileQueryParams } from '@cyber-co/utils';
import {
  CreateWorkExperienceInput,
  CreateWorkExperienceResponse_Status,
  UpdateWorkExperienceInput,
  UpdateWorkExperienceResponse_Status,
  api,
  useCreateWorkExperienceMutation,
  useUpdateWorkExperienceMutation,
} from '@cyber/service/pwa';
import { toast } from '@cyberlab/uikit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export type TUpdateWorkExpParams = Omit<CreateWorkExperienceInput, 'profileId'> & {
  handle: string;
  profileId: string;
  workExperienceId?: string;
};

export const useUpdateWorkExp: () => [
  updater: (p: TUpdateWorkExpParams) => Promise<{ isSuccess: boolean }>,
  result: any,
] = () => {
  const dispatch: any = useDispatch();

  const [updater, updateResult] = useUpdateWorkExperienceMutation();
  const [creator, createResult] = useCreateWorkExperienceMutation();

  const updateEducation = async (input: UpdateWorkExperienceInput) =>
    updater({ input }).then((data) => {
      if ('data' in data && data.data.updateWorkExperience.status === UpdateWorkExperienceResponse_Status.Success) {
        return { isSuccess: true };
      }

      return { isSuccess: false };
    });

  const createEducation = async (input: CreateWorkExperienceInput) =>
    creator({ input }).then((data) => {
      if ('data' in data && data.data.createWorkExperience.status === CreateWorkExperienceResponse_Status.Success) {
        return { isSuccess: true };
      }

      return { isSuccess: false };
    });

  const handleUpdate = useCallback(
    async ({ profileId, workExperienceId, handle, ...input }: TUpdateWorkExpParams) => {
      const res = await (workExperienceId
        ? updateEducation({
            workExperienceId,
            ...input,
          })
        : createEducation({ profileId, ...input }));

      if (res.isSuccess) {
        await dispatch(api.endpoints.getProfile.initiate(getProfileQueryParams(handle), { forceRefetch: true }));

        toast.success('Update Successfully!');
        revalidateSSGPage(handle);
      }

      return res;
    },
    [updater],
  );

  return [handleUpdate, updateResult || createResult];
};
