import { fixTwitterLink } from '@cyber-co/utils';
import { Twitter } from '@cyber/service/pwa';
import { Flex, Stack, Text } from '@cyberlab/uikit';
import Icon from '@link3-pwa/icons';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import CreateAndVerifyButton from './Profile/EAS/OrgMemberVerify/CreateAndVerifyButton';

function Headline({
  data,
  isVerified,
  couldClaim,
  verifyIcon,
  profileId,
  showVerify = false,
}: {
  data?: Partial<Twitter>;
  isVerified?: boolean;
  couldClaim?: boolean;
  profileId?: string;
  verifyIcon?: ReactNode;
  showVerify?: boolean;
}) {
  const { push } = useRouter();
  const handleGo2CompanyPage = () => {
    if (data?.profileId) {
      push(`/${data.profileId}`);
    } else if (data?.twitterId) {
      window.open(fixTwitterLink({ id: data.twitterId }));
    } else {
      return;
    }
  };

  const showBadge = isVerified || couldClaim;

  return (
    <Flex alignItems='center' gap={0.5} flexWrap='wrap' width='100%'>
      <Text variant='body2' color='text.250' onClick={handleGo2CompanyPage} noWrap>
        {data?.title}
      </Text>
      {data?.displayName ? (
        <Text variant='body2' color='text.100'>
          @{data?.displayName}
        </Text>
      ) : null}

      {showBadge ? (
        verifyIcon ?? <Icon name='icon-Verified-person-fill' size={18} color='brand.main' />
      ) : showVerify ? (
        <CreateAndVerifyButton hasHeadline={!!data?.title} profileId={profileId} />
      ) : null}
    </Flex>
  );
}

export default Headline;
