import { useCouldEditProfile } from '@/hooks/profile';
import { Flex, Menu, MenuItem, Text } from '@cyberlab/uikit';
import Icon from '@link3-pwa/icons';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { PropsWithChildren, useRef, useState } from 'react';

const PreviewModal = dynamic(() => import('./PreviewModal'), { ssr: false });
const ProfileShareModal = dynamic(() => import('./ProfileShareModal'), { ssr: false });
const BlockSortModal = dynamic(() => import('./BlockSortModal'), { ssr: false });

const ProfileMenu = (props: PropsWithChildren<{ anchorEl: Element | null; onClose: () => void; open: boolean }>) => {
  return (
    <Menu 
      autoFocus={false} 
      isBlur 
      needBorder 
      {...props} 
      onBlur={props.onClose}
      sx={{
        '& .MuiMenu-paper': {
          borderColor: 'border.100',
        },
        '& .MuiMenu-list': {
          backgroundColor: 'bg.100',
          '& .MuiMenuItem-root': {
            borderColor: 'border.100'
          }
        },
      }} 
    />
  )
};

function Header({ handle }: { handle?: string }) {
  const {
    back,
    query: { handle: handleFromQuery, mode },
  } = useRouter();

  const { couldEdit, isPreview } = useCouldEditProfile();

  const menuAnchorRef = useRef<HTMLDivElement>(null);
  const [menuOpen, toggleMenuOpen] = useState(false);
  const [isPreivewOpen, toggleIsPreviewOpen] = useState(false);
  const [isShareOpen, toggleIsShareOpen] = useState(false);
  const [isSortSectionOpen, toggleIsSortSectionOpen] = useState(false);

  const openMenu = () => {
    toggleMenuOpen(true);
  };

  const closeMenu = () => {
    toggleMenuOpen(false);
  };

  const openPreview = () => {
    toggleIsPreviewOpen(true);
  };

  const closePreview = () => {
    toggleIsPreviewOpen(false);
  };

  const openShare = () => {
    toggleIsShareOpen(true);
  };

  const closeShare = () => {
    toggleIsShareOpen(false);
  };

  const openSortSection = () => {
    toggleIsSortSectionOpen(true);
  };

  const closeSortSection = () => {
    toggleIsSortSectionOpen(false);
  };

  return (
    <Flex
      bgcolor='primary.contrastText'
      mb={-60 / 8}
      alignItems='center'
      width='100%'
      position='sticky'
      top={0}
      py={1.5}
      px={2}
      zIndex={1}
      left={0}
      sx={({ palette }) => ({
        bgcolor: palette.mode === 'light' ? 'rgba(255,255,255,0.5)' : 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(10px)',
      })}
    >
      <Icon
        onClick={isPreview ? closePreview : back}
        name='icon-arrrow-tiny-up'
        size={36}
        sx={{ transform: 'rotate(-90deg)' }}
        color='#f0f0f0'
      />

      <Text color='#f0f0f0' width='100%' textAlign='center' bold variant='subtitle1'>
        Profile
      </Text>

      {couldEdit ? (
        <>
          <Flex
            position='relative'
            width={36}
            height={36}
            ref={menuAnchorRef}
            alignItems='center'
            justifyContent='center'
            onClick={openMenu}
          >
            <Icon name='icon-more-line' size={24} color='#f0f0f0' />
          </Flex>

          <ProfileMenu anchorEl={menuAnchorRef.current} onClose={closeMenu} open={menuOpen}>
            <MenuItem
              sx={{
                color: '#f0f0f0',
              }}
              onClick={() => {
                openPreview();
                closeMenu();
              }}
            >
              Preview
            </MenuItem>
            <MenuItem
              sx={{
                color: '#f0f0f0',
              }}
              onClick={() => {
                openSortSection();
                closeMenu();
              }}
            >
              Sort Section
            </MenuItem>
            {handle ? (
              <MenuItem
                sx={{
                  color: '#f0f0f0',
                }}
                onClick={() => {
                  openShare();
                  closeMenu();
                }}
              >
                Share My Profile
              </MenuItem>
            ) : null}
            {/* <MenuItem>Sort Section</MenuItem>
      <MenuItem>Share My Profile</MenuItem> */}
          </ProfileMenu>

          <PreviewModal open={isPreivewOpen} onClose={closePreview} />
          <BlockSortModal
            open={isSortSectionOpen}
            handleFromQuery={handleFromQuery as string}
            onClose={closeSortSection}
          />
        </>
      ) : (
        <Flex
          width={36}
          height={36}
          onClick={openShare}
          position='relative'
          alignItems='center'
          justifyContent='center'
        >
          <Icon name='icon-share-line' size={24} color='#f0f0f0' />
        </Flex>
      )}
      <ProfileShareModal open={isShareOpen} handleFromQuery={handleFromQuery as string} onClose={closeShare} />
    </Flex>
  );
}

export default Header;
