import { useCouldEditProfile } from '@/hooks/profile';
import { Block } from '@cyber/service/pwa';
import { Progress } from '@cyberlab/uikit';
import { useMemo } from 'react';
import BlockWrapper from './core/BlockWrapper';

function ProfileCompleteness(props: { blocks: Block[] }) {
  const { blocks } = props;

  const { couldEdit } = useCouldEditProfile();

  const percentage = useMemo(() => {
    return Math.min(blocks.filter((b) => !b.isPlaceHolder).length, 10) * 10;
  }, [blocks]);

  const getColor = () => {
    if (percentage <= 30) {
      return 'error.main';
    } else if (percentage <= 60) {
      return 'warning.main';
    } else {
      return 'success.main';
    }
  };

  if (!couldEdit) return null;

  return (
    <BlockWrapper
      title={'Profile Completeness'}
      key='profile-completeness'
      contentProps={{ gap: 3, sx: { p: 0 }, alignItems: 'end' }}
      noDivider
      editButton={<Progress color={getColor()} percentage={percentage} width={100} />}
    >
      {/* <Text width='100%'>Improve your reputation by enhancing your profile.</Text> */}

      {/* <Button variant='contained' color='primary' sx={{ height: 28, px: 1.5, width: 'fit-content' }}>
        Add Featured Content
      </Button> */}
    </BlockWrapper>
  );
}

export default ProfileCompleteness;
