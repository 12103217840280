const TWITTER_SCOPES = ['tweet.read', 'users.read', 'follows.read', 'offline.access', 'like.read'];

export const redirectGetGithubCode = (from: string = location.pathname) => {
  const redirectUri = window.origin + '/redirect/github';
  const state = `from=${from}`;

  const authUrl =
    typeof window !== 'undefined'
      ? `https://github.com/login/oauth/authorize?client_id=Iv1.528aed785c292063&redirect_uri=${redirectUri}&state=${state}`
      : '';

  window.location.replace(authUrl);
};

export function decodeState(state: string): string {
  if (!state) return '';
  return state.split('=')[1] || '/me/account-setting/social-media';
}
