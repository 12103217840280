import { Nft, PersonalPicture_Type } from '@cyber/service/pwa';
import { Box, Flex, RatioBox, styled } from '@cyberlab/uikit';

interface IProfileBackgroundProps {
  src?: string;
  type?: PersonalPicture_Type;
  nfts?: Nft[] | null;
}

const NFTWallITem = styled('div')<{ src: string }>(({ src, theme }) => ({
  width: '100%',
  height: '100%',
  background: `url(${src}) no-repeat 100%`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

function ProfileBackground(props: IProfileBackgroundProps) {
  const { type, src, nfts } = props;

  const renderBackground = () => {
    if (type === PersonalPicture_Type.Nft) {
      return (
        <Flex height='100%' justifyContent='center'>
          {nfts?.map((nft) => (
            <NFTWallITem key={`${nft.contract}-${nft.tokenId}`} src={nft.imageUrl} sx={{ width: '30rem' }} />
          ))}
        </Flex>
      );
    }

    if (!!src) {
      return (
        <Box
          component='img'
          position='absolute'
          width='100%'
          height='100%'
          left={0}
          top={0}
          src={props.src}
          sx={{
            objectFit: 'cover',
          }}
        />
      );
    }

    return null;
  };
  return (
    <RatioBox ratio={145 / 390}>
      {renderBackground()}
      <Box width='100%' height='100%' bgcolor='#000' position='absolute' sx={{ opacity: 0.5 }} />
    </RatioBox>
  );
}

export default ProfileBackground;
