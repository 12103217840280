import Wrapper from '@/component/Wrapper';
import { Box, Divider, Flex, StackProps, Text } from '@cyberlab/uikit';
import { PropsWithChildren, ReactNode } from 'react';

interface IBlockWrapperProps {
  title: ReactNode;
  contentProps?: StackProps;
  editButton?: ReactNode;
  subtitle?: ReactNode;
  noDivider?: boolean;
}

function BlockWrapper(props: PropsWithChildren<IBlockWrapperProps>) {
  const { children, title, contentProps, editButton, subtitle, noDivider } = props;
  return (
    <Box>
      <Wrapper pt={4}>
        <Flex alignItems='center' justifyContent='space-between'>
          <Flex gap={1} alignItems='center'>
            <Text lines={1} variant='body1' bold color='text.100'>
              {title}
            </Text>
          </Flex>

          {editButton}
        </Flex>
      </Wrapper>
      {subtitle ? subtitle : !noDivider ? <Divider /> : null}

      <Wrapper pt={4} {...contentProps}>
        {children}
      </Wrapper>
    </Box>
  );
}

export default BlockWrapper;
