import { RatioBox, Stack, StyledImage, Text } from '@cyberlab/uikit';

const NftNameCover = ({ name }: { name: string }) => (
  <Stack
    alignItems='center'
    justifyContent='center'
    className='nft-name'
    display='none'
    position='absolute'
    width='100%'
    height='100%'
    sx={({ palette }) => ({
      bgcolor: palette.mode === 'dark' ? 'rgba(0,0,0,0.5)' : 'rgba(255,255,255,0.5)',
      border: '1px solid',
      borderColor: 'border.100',
      borderRadius: 1,
      backdropFilter: 'blur(5px)',
    })}
  >
    <Text mx={1.5} variant='body2' bold color='text.100'>
      {name}
    </Text>
  </Stack>
);

function NFTItem({ name, imageUrl }: { name?: string; imageUrl?: string }) {
  return (
    <Stack gap={1.5}>
      <RatioBox
        sx={{
          borderRadius: 1,
          overflow: 'hidden',
          '&:hover': {
            '.nft-name': {
              display: 'flex',
            },
          },
        }}
      >
        <StyledImage
          src={imageUrl}
          sx={{
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            position: 'absolute',
            objectFit: 'cover',
          }}
        />

        {name ? <NftNameCover name={name} /> : null}
      </RatioBox>
    </Stack>
  );
}

export default NFTItem;
