import { api } from '../client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
};

export type Alias = {
  __typename?: 'Alias';
  address: Scalars['String'];
  alias: Scalars['String'];
  created: Scalars['Int'];
  id: Scalars['String'];
  ipfs?: Maybe<Scalars['String']>;
};

export type AliasWhere = {
  address?: Maybe<Scalars['String']>;
  address_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  alias?: Maybe<Scalars['String']>;
  alias_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  created?: Maybe<Scalars['Int']>;
  created_gt?: Maybe<Scalars['Int']>;
  created_gte?: Maybe<Scalars['Int']>;
  created_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  created_lt?: Maybe<Scalars['Int']>;
  created_lte?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ipfs?: Maybe<Scalars['String']>;
  ipfs_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DelegationPortal = {
  __typename?: 'DelegationPortal';
  delegationApi: Scalars['String'];
  delegationContract: Scalars['String'];
  delegationType: Scalars['String'];
};

export type Follow = {
  __typename?: 'Follow';
  created: Scalars['Int'];
  follower: Scalars['String'];
  id: Scalars['String'];
  ipfs?: Maybe<Scalars['String']>;
  space: Space;
};

export type FollowWhere = {
  created?: Maybe<Scalars['Int']>;
  created_gt?: Maybe<Scalars['Int']>;
  created_gte?: Maybe<Scalars['Int']>;
  created_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  created_lt?: Maybe<Scalars['Int']>;
  created_lte?: Maybe<Scalars['Int']>;
  follower?: Maybe<Scalars['String']>;
  follower_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ipfs?: Maybe<Scalars['String']>;
  ipfs_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  space?: Maybe<Scalars['String']>;
  space_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Item = {
  __typename?: 'Item';
  id: Scalars['String'];
  spacesCount?: Maybe<Scalars['Int']>;
};

export type Message = {
  __typename?: 'Message';
  address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ipfs?: Maybe<Scalars['String']>;
  mci?: Maybe<Scalars['Int']>;
  receipt?: Maybe<Scalars['String']>;
  sig?: Maybe<Scalars['String']>;
  space?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type MessageWhere = {
  address?: Maybe<Scalars['String']>;
  address_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  mci?: Maybe<Scalars['Int']>;
  mci_gt?: Maybe<Scalars['Int']>;
  mci_gte?: Maybe<Scalars['Int']>;
  mci_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  mci_lt?: Maybe<Scalars['Int']>;
  mci_lte?: Maybe<Scalars['Int']>;
  space?: Maybe<Scalars['String']>;
  space_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  timestamp?: Maybe<Scalars['Int']>;
  timestamp_gt?: Maybe<Scalars['Int']>;
  timestamp_gte?: Maybe<Scalars['Int']>;
  timestamp_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  timestamp_lt?: Maybe<Scalars['Int']>;
  timestamp_lte?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Metrics = {
  __typename?: 'Metrics';
  categories?: Maybe<Scalars['Any']>;
  total?: Maybe<Scalars['Int']>;
};

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Proposal = {
  __typename?: 'Proposal';
  app?: Maybe<Scalars['String']>;
  author: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  choices: Array<Maybe<Scalars['String']>>;
  created: Scalars['Int'];
  discussion: Scalars['String'];
  end: Scalars['Int'];
  flagged?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  ipfs?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  plugins: Scalars['Any'];
  privacy?: Maybe<Scalars['String']>;
  quorum: Scalars['Float'];
  scores?: Maybe<Array<Maybe<Scalars['Float']>>>;
  scores_by_strategy?: Maybe<Scalars['Any']>;
  scores_state?: Maybe<Scalars['String']>;
  scores_total?: Maybe<Scalars['Float']>;
  scores_updated?: Maybe<Scalars['Int']>;
  snapshot?: Maybe<Scalars['String']>;
  space?: Maybe<Space>;
  start: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  strategies: Array<Maybe<Strategy>>;
  symbol: Scalars['String'];
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  validation?: Maybe<Validation>;
  votes?: Maybe<Scalars['Int']>;
};

export type ProposalWhere = {
  app?: Maybe<Scalars['String']>;
  app_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  app_not?: Maybe<Scalars['String']>;
  app_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  author?: Maybe<Scalars['String']>;
  author_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  created?: Maybe<Scalars['Int']>;
  created_gt?: Maybe<Scalars['Int']>;
  created_gte?: Maybe<Scalars['Int']>;
  created_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  created_lt?: Maybe<Scalars['Int']>;
  created_lte?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  end_gt?: Maybe<Scalars['Int']>;
  end_gte?: Maybe<Scalars['Int']>;
  end_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  end_lt?: Maybe<Scalars['Int']>;
  end_lte?: Maybe<Scalars['Int']>;
  flagged?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ipfs?: Maybe<Scalars['String']>;
  ipfs_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  network?: Maybe<Scalars['String']>;
  network_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  plugins_contains?: Maybe<Scalars['String']>;
  scores_state?: Maybe<Scalars['String']>;
  scores_state_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  space?: Maybe<Scalars['String']>;
  space_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  space_verified?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['Int']>;
  start_gt?: Maybe<Scalars['Int']>;
  start_gte?: Maybe<Scalars['Int']>;
  start_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  start_lt?: Maybe<Scalars['Int']>;
  start_lte?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  strategies_contains?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updated?: Maybe<Scalars['Int']>;
  updated_gt?: Maybe<Scalars['Int']>;
  updated_gte?: Maybe<Scalars['Int']>;
  updated_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  updated_lt?: Maybe<Scalars['Int']>;
  updated_lte?: Maybe<Scalars['Int']>;
  validation?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  aliases?: Maybe<Array<Maybe<Alias>>>;
  follows?: Maybe<Array<Maybe<Follow>>>;
  messages?: Maybe<Array<Maybe<Message>>>;
  networks?: Maybe<Array<Maybe<Item>>>;
  plugins?: Maybe<Array<Maybe<Item>>>;
  proposal?: Maybe<Proposal>;
  proposals?: Maybe<Array<Maybe<Proposal>>>;
  ranking?: Maybe<RankingObject>;
  roles?: Maybe<Array<Maybe<Role>>>;
  skins?: Maybe<Array<Maybe<Item>>>;
  space?: Maybe<Space>;
  spaces?: Maybe<Array<Maybe<Space>>>;
  statement?: Maybe<Statement>;
  statements?: Maybe<Array<Maybe<Statement>>>;
  strategies?: Maybe<Array<Maybe<StrategyItem>>>;
  strategy?: Maybe<StrategyItem>;
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
  validations?: Maybe<Array<Maybe<Item>>>;
  vote?: Maybe<Vote>;
  votes?: Maybe<Array<Maybe<Vote>>>;
  vp?: Maybe<Vp>;
};


export type QueryAliasesArgs = {
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<AliasWhere>;
};


export type QueryFollowsArgs = {
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<FollowWhere>;
};


export type QueryMessagesArgs = {
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<MessageWhere>;
};


export type QueryProposalArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryProposalsArgs = {
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<ProposalWhere>;
};


export type QueryRankingArgs = {
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<RankingWhere>;
};


export type QueryRolesArgs = {
  where?: Maybe<RolesWhere>;
};


export type QuerySpaceArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QuerySpacesArgs = {
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<SpaceWhere>;
};


export type QueryStatementArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryStatementsArgs = {
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<StatementsWhere>;
};


export type QueryStrategyArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QuerySubscriptionsArgs = {
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<SubscriptionWhere>;
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<UsersWhere>;
};


export type QueryVoteArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryVotesArgs = {
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<VoteWhere>;
};


export type QueryVpArgs = {
  proposal?: Maybe<Scalars['String']>;
  space: Scalars['String'];
  voter: Scalars['String'];
};

export type RankingObject = {
  __typename?: 'RankingObject';
  items?: Maybe<Array<Maybe<Space>>>;
  metrics?: Maybe<Metrics>;
};

export type RankingWhere = {
  category?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  network?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type Role = {
  __typename?: 'Role';
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  space?: Maybe<Scalars['String']>;
};

export type RolesWhere = {
  address?: Maybe<Scalars['String']>;
};

export type Space = {
  __typename?: 'Space';
  about?: Maybe<Scalars['String']>;
  activeProposals?: Maybe<Scalars['Int']>;
  admins?: Maybe<Array<Maybe<Scalars['String']>>>;
  avatar?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  children?: Maybe<Array<Maybe<Space>>>;
  coingecko?: Maybe<Scalars['String']>;
  created: Scalars['Int'];
  delegationPortal?: Maybe<DelegationPortal>;
  domain?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  filters?: Maybe<SpaceFilters>;
  flagged?: Maybe<Scalars['Boolean']>;
  followersCount?: Maybe<Scalars['Int']>;
  followersCount7d?: Maybe<Scalars['Int']>;
  github?: Maybe<Scalars['String']>;
  guidelines?: Maybe<Scalars['String']>;
  hibernated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Maybe<Scalars['String']>>>;
  moderators?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  parent?: Maybe<Space>;
  plugins?: Maybe<Scalars['Any']>;
  private?: Maybe<Scalars['Boolean']>;
  proposalsCount?: Maybe<Scalars['Int']>;
  proposalsCount7d?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Float']>;
  skin?: Maybe<Scalars['String']>;
  strategies?: Maybe<Array<Maybe<Strategy>>>;
  symbol?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
  treasuries?: Maybe<Array<Maybe<Treasury>>>;
  turbo?: Maybe<Scalars['Boolean']>;
  twitter?: Maybe<Scalars['String']>;
  validation?: Maybe<Validation>;
  verified?: Maybe<Scalars['Boolean']>;
  voteValidation?: Maybe<Validation>;
  votesCount?: Maybe<Scalars['Int']>;
  votesCount7d?: Maybe<Scalars['Int']>;
  voting?: Maybe<SpaceVoting>;
  website?: Maybe<Scalars['String']>;
};

export type SpaceFilters = {
  __typename?: 'SpaceFilters';
  minScore?: Maybe<Scalars['Float']>;
  onlyMembers?: Maybe<Scalars['Boolean']>;
};

export type SpaceVoting = {
  __typename?: 'SpaceVoting';
  aliased?: Maybe<Scalars['Boolean']>;
  blind?: Maybe<Scalars['Boolean']>;
  delay?: Maybe<Scalars['Int']>;
  hideAbstain?: Maybe<Scalars['Boolean']>;
  period?: Maybe<Scalars['Int']>;
  privacy?: Maybe<Scalars['String']>;
  quorum?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type SpaceWhere = {
  created?: Maybe<Scalars['Int']>;
  created_gt?: Maybe<Scalars['Int']>;
  created_gte?: Maybe<Scalars['Int']>;
  created_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  created_lt?: Maybe<Scalars['Int']>;
  created_lte?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Statement = {
  __typename?: 'Statement';
  about?: Maybe<Scalars['String']>;
  created: Scalars['Int'];
  delegate?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  ipfs: Scalars['String'];
  space: Scalars['String'];
  statement?: Maybe<Scalars['String']>;
  updated: Scalars['Int'];
};

export type StatementsWhere = {
  created?: Maybe<Scalars['Int']>;
  created_gt?: Maybe<Scalars['Int']>;
  created_gte?: Maybe<Scalars['Int']>;
  created_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  created_lt?: Maybe<Scalars['Int']>;
  created_lte?: Maybe<Scalars['Int']>;
  delegate?: Maybe<Scalars['String']>;
  delegate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ipfs?: Maybe<Scalars['String']>;
  ipfs_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  space?: Maybe<Scalars['String']>;
  space_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Strategy = {
  __typename?: 'Strategy';
  name: Scalars['String'];
  network?: Maybe<Scalars['String']>;
  params?: Maybe<Scalars['Any']>;
};

export type StrategyItem = {
  __typename?: 'StrategyItem';
  about?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  examples?: Maybe<Array<Maybe<Scalars['Any']>>>;
  id: Scalars['String'];
  schema?: Maybe<Scalars['Any']>;
  spacesCount?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  address: Scalars['String'];
  created: Scalars['Int'];
  id: Scalars['String'];
  ipfs?: Maybe<Scalars['String']>;
  space: Space;
};

export type SubscriptionWhere = {
  address?: Maybe<Scalars['String']>;
  address_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  created?: Maybe<Scalars['Int']>;
  created_gt?: Maybe<Scalars['Int']>;
  created_gte?: Maybe<Scalars['Int']>;
  created_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  created_lt?: Maybe<Scalars['Int']>;
  created_lte?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ipfs?: Maybe<Scalars['String']>;
  ipfs_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  space?: Maybe<Scalars['String']>;
  space_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Treasury = {
  __typename?: 'Treasury';
  address?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  about?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  created: Scalars['Int'];
  id: Scalars['String'];
  ipfs?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UsersWhere = {
  created?: Maybe<Scalars['Int']>;
  created_gt?: Maybe<Scalars['Int']>;
  created_gte?: Maybe<Scalars['Int']>;
  created_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  created_lt?: Maybe<Scalars['Int']>;
  created_lte?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ipfs?: Maybe<Scalars['String']>;
  ipfs_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Validation = {
  __typename?: 'Validation';
  name: Scalars['String'];
  params?: Maybe<Scalars['Any']>;
};

export type Vote = {
  __typename?: 'Vote';
  app?: Maybe<Scalars['String']>;
  choice: Scalars['Any'];
  created: Scalars['Int'];
  id: Scalars['String'];
  ipfs?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Any']>;
  proposal?: Maybe<Proposal>;
  reason?: Maybe<Scalars['String']>;
  space: Space;
  voter: Scalars['String'];
  vp?: Maybe<Scalars['Float']>;
  vp_by_strategy?: Maybe<Array<Maybe<Scalars['Float']>>>;
  vp_state?: Maybe<Scalars['String']>;
};

export type VoteWhere = {
  app?: Maybe<Scalars['String']>;
  app_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  app_not?: Maybe<Scalars['String']>;
  app_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  created?: Maybe<Scalars['Int']>;
  created_gt?: Maybe<Scalars['Int']>;
  created_gte?: Maybe<Scalars['Int']>;
  created_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  created_lt?: Maybe<Scalars['Int']>;
  created_lte?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ipfs?: Maybe<Scalars['String']>;
  ipfs_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  proposal?: Maybe<Scalars['String']>;
  proposal_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  reason?: Maybe<Scalars['String']>;
  reason_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  reason_not?: Maybe<Scalars['String']>;
  reason_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  space?: Maybe<Scalars['String']>;
  space_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  voter?: Maybe<Scalars['String']>;
  voter_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  vp?: Maybe<Scalars['Float']>;
  vp_gt?: Maybe<Scalars['Float']>;
  vp_gte?: Maybe<Scalars['Float']>;
  vp_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  vp_lt?: Maybe<Scalars['Float']>;
  vp_lte?: Maybe<Scalars['Float']>;
  vp_state?: Maybe<Scalars['String']>;
  vp_state_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Vp = {
  __typename?: 'Vp';
  vp?: Maybe<Scalars['Float']>;
  vp_by_strategy?: Maybe<Array<Maybe<Scalars['Float']>>>;
  vp_state?: Maybe<Scalars['String']>;
};

export type GetProposalsInSpaceQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  spaceId?: Maybe<Scalars['String']>;
}>;


export type GetProposalsInSpaceQuery = { __typename?: 'Query', proposals?: Array<{ __typename?: 'Proposal', id: string, title: string, body?: string | null | undefined, start: number, end: number, state?: string | null | undefined } | null | undefined> | null | undefined };

export type GetSpaceByIdQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type GetSpaceByIdQuery = { __typename?: 'Query', space?: { __typename?: 'Space', id: string, avatar?: string | null | undefined, followersCount?: number | null | undefined, name?: string | null | undefined } | null | undefined };

export type QuerySpacesQueryVariables = Exact<{
  search: Scalars['String'];
}>;


export type QuerySpacesQuery = { __typename?: 'Query', ranking?: { __typename?: 'RankingObject', items?: Array<{ __typename?: 'Space', id: string, name?: string | null | undefined, avatar?: string | null | undefined, followers?: number | null | undefined } | null | undefined> | null | undefined } | null | undefined };


export const GetProposalsInSpaceDocument = `
    query getProposalsInSpace($first: Int = 3, $spaceId: String) {
  proposals(first: $first, where: {space: $spaceId}) {
    id
    title
    body
    start
    end
    state
  }
}
    `;
export const GetSpaceByIdDocument = `
    query getSpaceById($id: String) {
  space(id: $id) {
    id
    avatar
    followersCount
    name
  }
}
    `;
export const QuerySpacesDocument = `
    query querySpaces($search: String!) {
  ranking(first: 10, where: {search: $search}) {
    items {
      id
      name
      followers: followersCount
      avatar
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProposalsInSpace: build.query<GetProposalsInSpaceQuery, GetProposalsInSpaceQueryVariables | void>({
      query: (variables) => ({ document: GetProposalsInSpaceDocument, variables })
    }),
    getSpaceById: build.query<GetSpaceByIdQuery, GetSpaceByIdQueryVariables | void>({
      query: (variables) => ({ document: GetSpaceByIdDocument, variables })
    }),
    querySpaces: build.query<QuerySpacesQuery, QuerySpacesQueryVariables>({
      query: (variables) => ({ document: QuerySpacesDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetProposalsInSpaceQuery, useLazyGetProposalsInSpaceQuery, useGetSpaceByIdQuery, useLazyGetSpaceByIdQuery, useQuerySpacesQuery, useLazyQuerySpacesQuery } = injectedRtkApi;

