import { TNFTCollection, TNFTContent } from '@cyber/service/api';
import { Box, Collapse, Flex, Text } from '@cyberlab/uikit';
import { Icon } from '@link3-pwa/icons';
import { ReactNode, useState } from 'react';

interface INFTCollectionProps {
  collection: TNFTCollection;
  renderNFTs: (NFTs: TNFTContent[]) => ReactNode;
  onSelectedAll?: (collection: TNFTCollection) => void;
  // selected?: NftFragment[];
}

const Title = (props: {
  isAllSelected: boolean;
  isOpen: boolean;
  onClick: () => void;
  collectionName: string;
  onSelectedAll?: () => void;
}) => {
  const { isAllSelected, isOpen, collectionName } = props;
  const { onClick, onSelectedAll } = props;

  return (
    <Flex
      onClick={onClick}
      justifyContent='space-between'
      p={3}
      borderBottom={isOpen ? '1px solid' : 'none'}
      borderColor='border.100'
    >
      {/* <Flex gap={1}>
        <Radio
          checked={isAllSelected}
          onClick={(e) => {
            e.stopPropagation();

            onSelectedAll();
          }}
        /> */}

      <Text lines={1} variant='body1'>
        {collectionName}
      </Text>
      {/* </Flex> */}

      <Icon
        name='icon-arrrow-tiny-up'
        sx={{ transform: !isOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all 0.3s' }}
      />
    </Flex>
  );
};

function NftCollection(props: INFTCollectionProps) {
  const { collection, renderNFTs, onSelectedAll } = props;

  const [isOpen, toggleIsOpen] = useState(false);

  return (
    <Box bgcolor='bg.paper'>
      <Title
        collectionName={collection.collection_name}
        isAllSelected
        isOpen={isOpen}
        // onSelectedAll={() => onSelectedAll(collection)}
        onClick={() => {
          toggleIsOpen((state) => !state);
        }}
      />

      <Collapse in={isOpen}>
        <Box
          p={3}
          sx={{
            display: 'grid',
            gap: 3,
            gridTemplateColumns: `repeat(3, 1fr)`,
          }}
        >
          {renderNFTs(collection.assets)}
        </Box>
      </Collapse>
    </Box>
  );
}

export default NftCollection;
