import { Event } from '@cyber/service/pwa';

import { Flex, Stack, Tag, Text, styled } from '@cyberlab/uikit';
import { Icon } from '@link3-pwa/icons';
import { format } from 'date-fns';

const Container = styled(Stack)(({ theme: { palette, shape, spacing } }) => ({
  border: `1px solid ${palette.border?.[100]}`,
  borderRadius: shape.borderRadius,
  padding: spacing(1.5),
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

function EventMiniCard({ event }: { event: Partial<Event> }) {
  const hasExtraInfo = event.lightInfo?.hasRaffle || event.lightInfo?.hasW3ST;
  return (
    <Container>
      <Stack gap={1} width='75%'>
        <Text bold color='text.100'>
          {event.title}
        </Text>

        {hasExtraInfo ? (
          <Flex gap={0.5}>
            {event?.lightInfo?.hasRaffle && (
              <Tag containerProps={{ px: 0.5 }}>
                <Icon name='icon-gift-line' size={18} />
              </Tag>
            )}
            {event?.lightInfo?.hasW3ST && (
              <Tag containerProps={{ px: 0.5 }}>
                <Icon name='icon-W3ST-line' size={18} />
              </Tag>
            )}
          </Flex>
        ) : null}
      </Stack>

      <Stack alignItems='end' gap={0.5}>
        <Text variant='body2' color='brand.main' bold>
          {format(event.startTimestamp * 1000, 'MMM dd')}
        </Text>

        <Text variant='body2' color='brand.main'>
          {format(event.startTimestamp * 1000, 'hh:mm')}
        </Text>
      </Stack>
    </Container>
  );
}

export default EventMiniCard;
