import OrgIcon from '@/component/Icons/OrgIcon';
import { useCouldEditProfile } from '@/hooks/profile';
import useEas from '@/hooks/profile/useEas';
import useModalState from '@/hooks/useModalState';
import { Flex } from '@cyberlab/uikit';
import Icon from '@link3-pwa/icons';
import EASVerificationModal from './EASVerificationModal';
import EasClaimModal from './EasClaimModal';

export interface IEASVerificationProps {
  profileId?: string;
  type: 'member' | 'org';
  orgName: string;
}

function EASVerification(props: IEASVerificationProps) {
  const { profileId, type, orgName } = props;
  const { isVerified, couldClaim, claimEAS, attestation, isClaiming } = useEas(profileId);
  const { closeModal, isOpen, openModal } = useModalState();
  const { closeModal: closeClaimModal, openModal: openClaimModal, isOpen: isCliamModalOpen } = useModalState();
  const { couldEdit } = useCouldEditProfile();

  const handleCliam = async () => {
    const { isSuccess } = await claimEAS();

    if (isSuccess) {
      closeClaimModal();
      openModal();
    }
  };

  const getIconSize = () => {
    if (type === 'member') return 18;

    return 24;
  };

  const getVerifiedIcon = () => {
    if (type === 'member') return <Icon name='icon-Verified-person-fill' size={getIconSize()} color='brand.main' />;

    return <OrgIcon />;
  };

  if (!isVerified && !couldClaim) {
    return null;
  }

  const handleIconClick = () => {
    if (!isVerified) return;
    if (attestation.id) openModal();
    if ((couldClaim || isClaiming) && type === 'member') openClaimModal();
  };

  return (
    <>
      <Flex alignItems='center' onClick={handleIconClick}>
        {!couldClaim && !isClaiming ? (
          getVerifiedIcon()
        ) : couldEdit ? (
          <Icon name='icon-unclaim' onClick={openClaimModal} size={getIconSize()} color='text.250' />
        ) : null}
        {/* {getVerifiedIcon()} */}
      </Flex>

      <EASVerificationModal
        orgName={orgName}
        type={type}
        attestation={attestation}
        open={isOpen}
        onClose={closeModal}
      />
      <EasClaimModal open={isCliamModalOpen} onClose={closeClaimModal} onClaim={handleCliam} />
    </>
  );
}

export default EASVerification;
