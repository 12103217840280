import Header from '@/component/Profile/Header';
import ProfileSkeleton from '@/component/Profile/Skeleton/Profile.skeleton';
import AddBlocks from '@/component/Profile/blocks/AddBlocks';
import OrgBasicInfo from '@/component/Profile/blocks/BasicInfo/OrgBasicInfo';
import PersonalBasicInfo from '@/component/Profile/blocks/BasicInfo/PersonalBasicInfo';
import Contact from '@/component/Profile/blocks/Contact';
import Credential from '@/component/Profile/blocks/Credential';
import Education from '@/component/Profile/blocks/Education';
import FeaturedContents from '@/component/Profile/blocks/FeaturedContents';
import Link3Event from '@/component/Profile/blocks/Link3Event';
import Link3Post from '@/component/Profile/blocks/Link3Post';
import Links from '@/component/Profile/blocks/Links';
import NFT from '@/component/Profile/blocks/NFTs';
import OpenSea from '@/component/Profile/blocks/OpenSea';
import ProfileCompleteness from '@/component/Profile/blocks/ProfileCompleteness';
import ProfileLink from '@/component/Profile/blocks/ProfileLink';
import Recommendation from '@/component/Profile/blocks/Recommendation';
import Skills from '@/component/Profile/blocks/Skills';
import Snapshot from '@/component/Profile/blocks/Snapshot';
import Twitter from '@/component/Profile/blocks/Twitter';
import W3st from '@/component/Profile/blocks/W3st';
import Works from '@/component/Profile/blocks/Work';
import { isOrgProfile, isPersonalProfile } from '@/component/Profile/types';
import { useCouldEditProfile } from '@/hooks/profile';
import { getProfileQueryParams } from '@cyber-co/utils';
import {
  Block,
  BlockType,
  BlockVisibility,
  ConnectStatus,
  Profile,
  Role,
  VerificationStatus,
  api,
  useGetProfileQuery,
} from '@cyber/service/pwa';
import { Stack } from '@cyberlab/uikit';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ProfileConfigContext from './ProfileConfig.context';

const FooterAction = dynamic(() => import('@/component/Profile/FooterAction'), { ssr: false });

const ADMIN_ROLE = [Role.Admin, Role.Owner];

// @ts-ignore
const BLOCK_MAP: any = new Map([
  [BlockType.Link, Links],
  [BlockType.NftGallery, NFT],
  [BlockType.Superlink, FeaturedContents],
  [BlockType.W3StGallery, W3st],
  [BlockType.Twitter, Twitter],
  [BlockType.Credential, Credential],
  [BlockType.NftCollection, OpenSea],
  [BlockType.Snapshot, Snapshot],
  [BlockType.Event, Link3Event],
  [BlockType.Posts, Link3Post],
  [BlockType.Skill, Skills],
  [BlockType.Recommendation, Recommendation],
  [BlockType.WorkExperience, Works],
  [BlockType.Education, Education],
  [BlockType.Contact, Contact],
]);

const Blocks = (props: { blocks?: Profile['blocks']; profileId: string }) => {
  const { blocks = [], profileId } = props;
  const { couldEdit } = useCouldEditProfile();

  const renderBlock = (block: Block) => {
    const Comp: any = BLOCK_MAP.get(block.type);

    if (Comp !== undefined) {
      return <Comp key={block.id} data={block} profileId={profileId} />;
    }

    return null;
  };

  return <>{blocks.map((block: Block) => renderBlock(block))}</>;
};

function Profile(props: { isPreview?: boolean }) {
  const { isPreview } = props;

  const {
    query: { handle: handleFromQuery },
    back,
  } = useRouter();

  const { handle, id } = getProfileQueryParams(handleFromQuery);
  const { couldEdit } = useCouldEditProfile();
  const dispatch: any = useDispatch();

  const { profile, isLoading } = useGetProfileQuery(
    { handle, id },
    {
      skip: !id && !handle,
      selectFromResult({ currentData, isLoading }) {
        return {
          profile: currentData?.profile.data,
          isLoading,
        };
      },
      refetchOnMountOrArgChange: true,
    },
  );

  // const { profileMetaData } = useGetProfileMetadataQuery(
  //   { handle, id },
  //   {
  //     skip: !id && !handle,
  //     selectFromResult({ currentData, isLoading }) {
  //       return {
  //         profileMetaData: currentData?.profile.data as any,
  //         isLoading,
  //       };
  //     },
  //   },
  // );

  useEffect(() => {
    if (isPersonalProfile(profile) && profile.id) {
      dispatch(api.endpoints.getProfileIdentities.initiate(getProfileQueryParams(profile.id)));
    }
  }, [profile?.id]);

  const renderFooter = () => {
    if (isPreview) return null;

    if (isPersonalProfile(profile) && !couldEdit) {
      return <FooterAction userId={profile.owner.id} profileId={profile.id} connectStatus={profile.connectStatus} />;
    }

    if (isOrgProfile(profile) && profile.organization.verification === VerificationStatus.Verified && !couldEdit) {
      return (
        <FooterAction
          connectStatus={ConnectStatus.Unconnected}
          userId={''}
          profileId={profile.id}
          type={profile.type}
          isFollowing={profile.isFollowing}
        />
      );
    }

    if (isOrgProfile(profile) && ADMIN_ROLE.includes(profile.organization.currentAccess)) {
      return (
        <FooterAction
          orgId={profile.organization.id}
          profileId={profile.id}
          type={'ADMIN'}
          isFollowing={profile.isFollowing}
        />
      );
    }

    return null;
  };

  const footer = renderFooter();

  if (isLoading) {
    return <ProfileSkeleton />;
  }

  return (
    <ProfileConfigContext isPreview={isPreview}>
      <Stack gap={1} pb={!!footer ? 12.25 : 1} position='relative' bgcolor='bg.default'>
        {isPreview ? null : <Header handle={profile?.handle} />}

        {isPersonalProfile(profile) ? <PersonalBasicInfo data={profile} /> : null}
        {isOrgProfile(profile) ? <OrgBasicInfo data={profile} /> : null}

        {isPersonalProfile(profile) ? <ProfileLink handle={profile?.handle} /> : null}
        {isPersonalProfile(profile) && profile?.blocks ? <ProfileCompleteness blocks={profile.blocks} /> : null}

        <Blocks
          blocks={profile?.blocks.filter(
            (block) =>
              couldEdit || (block.visibility !== BlockVisibility.BlockVisibilityPrivate && !block.isPlaceHolder),
          )}
          profileId={profile?.id || ''}
        />

        {profile?.blocks ? (
          <AddBlocks profileType={profile.type} profileId={profile?.id || ''} blocks={profile.blocks} />
        ) : null}

        {footer}
      </Stack>
    </ProfileConfigContext>
  );
}

export default Profile;
