import { FC, PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// type TProps = PropsWithChildren<IProfileFormDrawerProps>;

function withForm<TProps>(
  Content: FC<PropsWithChildren<TProps>>,
  formConfig?: { mode?: 'onBlur' | 'onChange' | 'onSubmit' | 'onTouched' | 'all' },
) {
  return (props: PropsWithChildren<TProps>) => {
    const formMethods = useForm({ mode: formConfig?.mode });

    return (
      <FormProvider {...formMethods}>
        <Content {...props} />
      </FormProvider>
    );
  };
}

export default withForm;
