import { DEFAULT_BLOCK_TITLE } from '@/configs/profile';
import { useCouldEditProfile } from '@/hooks/profile/useCouldEditProfile';
import useModalState from '@/hooks/useModalState';
import { BlockType, EducationBlock } from '@cyber/service/pwa';
import dynamic from 'next/dynamic';
import { useFormContext } from 'react-hook-form';
import { IBlockProps } from '../../types';
import BlockWrapper from '../core/BlockWrapper';
import EditButton from '../core/EditButton';
import withForm from '../core/withForm';
import { EditorProps, TFormValue } from './Education.edit';
import EducationItem from './EducationItem';

const EducationEditor = dynamic(() => import('./Education.edit'), { ssr: false });

interface IEducationProps extends IBlockProps {
  data?: EducationBlock;
}

function Education(props: IEducationProps) {
  const { data, isCreate, profileId } = props;
  const { couldEdit } = useCouldEditProfile();
  const { setValue } = useFormContext<TFormValue>();

  const { isOpen, closeModal, openModal } = useModalState();

  const handleEditItem = (id: string) => {
    setValue('educationId', id);

    openModal();
  };

  return (
    <BlockWrapper
      title={data?.displayName || DEFAULT_BLOCK_TITLE[BlockType.Education]}
      key={data?.id}
      contentProps={{ gap: 3, sx: { p: isCreate ? 0 : undefined } }}
      editButton={
        <EditButton<EducationBlock>
          isCreate
          hidden={!couldEdit}
          profileId={profileId}
          data={data}
          Modal={EducationEditor}
          openModal={openModal}
          closeModal={closeModal}
          isModalOpen={isOpen}
        />
      }
      noDivider={isCreate}
    >
      {!isCreate
        ? data?.educations.map((edu) => <EducationItem onEdit={handleEditItem} key={edu.id} data={edu} />)
        : null}
    </BlockWrapper>
  );
}

export default withForm<EditorProps>(Education as any);
