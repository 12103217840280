import { DEFAULT_BLOCK_TITLE } from '@/configs/profile';
import { useCouldEditProfile } from '@/hooks/profile/useCouldEditProfile';
import { BlockType, CredentialBlock } from '@cyber/service/pwa';
import { Button, Divider, Flex, Stack, Text } from '@cyberlab/uikit';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import { IBlockProps } from '../../types';
import BlockWrapper from '../core/BlockWrapper';
import EditButton from '../core/EditButton';
import NFTGrid from '../core/NFTGrid';
import NFTItem from '../core/NFTItem';
import CredItem from './CredItem';
const CredentialEditModal = dynamic(() => import('./Credential.edit'), { ssr: false });

interface INFTProps extends IBlockProps {
  data: CredentialBlock;
}

// const EditButton = (props: { profileId: string; data?: CredentialBlock; hidden: boolean }) => {
//   const { data, profileId, hidden } = props;

//   const [isModalOpen, toggleIsModal] = useState(false);

//   const openModal = () => toggleIsModal(true);
//   const closeModal = () => toggleIsModal(false);

//   if (!data || hidden) return null;

//   return (
//     <>
//       <EditIconButton onClick={openModal} />
//       <CredentialEditModal onClose={closeModal} open={isModalOpen} blockData={data} profileId={profileId} />
//     </>
//   );
// };

const credShowLimit = 3;

function Credential(props: INFTProps) {
  const { data, profileId, isCreate } = props;
  const creds = data.galaxyCreds;
  const poaps = data.poaps;
  const [showMore, setShowMore] = useState(false);

  const { couldEdit } = useCouldEditProfile();
  const more = creds ? creds.length - credShowLimit : 0;
  const credentials = more > 0 && !showMore ? creds?.slice(0, credShowLimit) : creds;

  return (
    <BlockWrapper
      key={data.id}
      title={data?.displayName || DEFAULT_BLOCK_TITLE[BlockType.Credential]}
      editButton={
        <EditButton<CredentialBlock>
          profileId={profileId}
          data={data}
          hidden={!couldEdit}
          isCreate={isCreate}
          Modal={CredentialEditModal}
        />
      }
      contentProps={{ sx: { p: isCreate ? 0 : undefined } }}
      noDivider={isCreate}
    >
      {creds ? (
        <Stack p={2}>
          <Text bold>Galaxy Credentials</Text>
          <Flex mt={3} flexWrap={'wrap'} rowGap={1.25} columnGap={1.5}>
            {credentials?.map((cred, index) => (
              <CredItem key={cred.id} name={cred.name} index={index} />
            ))}
            { more > 0 && (
              <Button
                variant='outlined'
                size='tiny'
                onClick={() => {
                  setShowMore(!showMore)
                }}
              >
                {showMore ? 'Show Less' : `+${more} More`} 
              </Button> 
            )}
          </Flex>
        </Stack>
      ) : null}
      {poaps && creds ? <Divider sx={{ my: 3 }} flexItem /> : null}
      {poaps ? (
        <Stack>
          <Text bold>POAP</Text>
          <NFTGrid itemLength={poaps.length} wrapperProps={{ mt: 3 }}>
            {/* TODO: poap onclick展示内容 */}
            {poaps.map((poap, index) => (
              <NFTItem key={poap.id + index} imageUrl={poap.imageUrl} />
            ))}
          </NFTGrid>
        </Stack>
      ) : null}
    </BlockWrapper>
  );
}

export default Credential;
