import { ProfileType, useGetProfileHandleByIdQuery } from '@cyber/service/pwa';
import { Flex, Stack, StackProps, Tag, Text } from '@cyberlab/uikit';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import GlobalAvatar from '../GlobalAvatar';
import OrgIcon from '../Icons/OrgIcon';
type UserProps = {
  avatar: string | undefined | null;
  type?: ProfileType;
  displayName?: ReactNode;
  profileId?: string | null;
  profileHandle?: string;
  desc: ReactNode; // headline
  wrapperProps?: StackProps;
};
export default function User(props: UserProps) {
  const { avatar, displayName, profileId, profileHandle, desc, wrapperProps, type = ProfileType.Personal } = props;
  const router = useRouter();

  const { data } = useGetProfileHandleByIdQuery({ id: profileId }, { skip: !profileId || !!profileHandle });
  const _profileHandle = profileHandle || data?.profile.data?.handle;

  const redirectToUser = () => {
    router.push(`/${profileId || _profileHandle}`);
  };

  return (
    <Flex justifyContent='space-between' alignItems='center' onClick={redirectToUser}>
      <Flex gap={1} alignItems='center' {...wrapperProps}>
        <GlobalAvatar size={40} src={avatar} type={type} />

        <Stack gap={2 / 8}>
          <Flex alignItems='center'>
            <Text color='text.100' bold variant='body1' lines={1}>
              {displayName}
            </Text>
            {type === ProfileType.Organization ? <OrgIcon size={18} /> : null}
          </Flex>

          <Text color='text.250' variant='caption'>
            {/* <Text display='inline' color='brand.light' variant='caption'> */}
            {desc}
            {/* </Text>{' '} */}
            {/* @CyberConnect */}
          </Text>
        </Stack>
      </Flex>

      {typeof desc === 'string' && desc.toLowerCase().includes('host') && <Tag>Hoster</Tag>}
    </Flex>
  );
}
