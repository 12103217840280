import { Box, Collapse, Stack, StackProps } from '@cyberlab/uikit';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import ShowMore from './ShowMore';

export default function NFTGrid({
  itemLength = 0,
  children,
  wrapperProps,
}: {
  itemLength?: number;
  children: ReactNode;
  wrapperProps?: StackProps;
}) {
  const contentRef = useRef<any>(null);
  const [isShowMore, setIsShowMore] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const needShowMore = itemLength && itemLength > 6;
  const columnCount = useMemo(() => {
    if (!itemLength) return '1';
    switch (itemLength) {
      case 1:
        return '1';
      case 2:
      case 4:
        return '2';
      default:
        return '3';
    }
  }, [itemLength]);

  useEffect(() => {
    if (contentRef.current) {
      const rowCount = Math.ceil(itemLength / Number(columnCount));
      // 两行的高度
      setContentHeight((contentRef.current.clientHeight / rowCount) * Math.min(2, rowCount));
    }
  }, [itemLength]);

  return (
    <Stack {...wrapperProps}>
      <Collapse in={isShowMore || !needShowMore} collapsedSize={contentHeight}>
        <Box
          sx={{
            display: 'grid',
            gap: 3,
            gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
          }}
          ref={contentRef}
        >
          {children}
        </Box>
      </Collapse>
      {needShowMore ? (
        <ShowMore
          isShowMore={isShowMore}
          setShowMore={() => {
            setIsShowMore(!isShowMore);
          }}
        />
      ) : null}
    </Stack>
  );
}
