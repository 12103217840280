import { DEFAULT_BLOCK_TITLE } from '@/configs/profile';
import { useCouldEditProfile } from '@/hooks/profile/useCouldEditProfile';
import { BlockType, SnapshotBlock } from '@cyber/service/pwa';
import dynamic from 'next/dynamic';
import { IBlockProps } from '../../types';
import BlockWrapper from '../core/BlockWrapper';
import EditButton from '../core/EditButton';
import SnapshotContent from './SnapshotContent';

const SnapshotEdit = dynamic(() => import('./Snapshot.edit'), { ssr: false });

interface ISnapshotProps extends IBlockProps {
  data: SnapshotBlock;
}

function Snapshot(props: ISnapshotProps) {
  const { data, profileId, isCreate } = props;

  const { couldEdit } = useCouldEditProfile();

  return (
    <BlockWrapper
      editButton={
        <EditButton<SnapshotBlock>
          profileId={profileId}
          data={data}
          hidden={!couldEdit}
          isCreate={isCreate}
          Modal={SnapshotEdit}
        />
      }
      title={data?.displayName || DEFAULT_BLOCK_TITLE[BlockType.Snapshot]}
      key={data?.id}
      contentProps={{ sx: { gap: 3, p: isCreate ? 0 : undefined } }}
      noDivider={isCreate}
    >
      <SnapshotContent spaceId={data.spaceId} />
    </BlockWrapper>
  );
}

export default Snapshot;
