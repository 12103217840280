import { Button, Flex, LoadingButton, Stack, Text } from '@cyberlab/uikit';
import Modal, { ModalProps } from '@cyberlab/uikit/src/Modal';
import { useState } from 'react';

interface IEasClaimModalProps extends Pick<ModalProps, 'open' | 'onClose'> {
  onClaim: () => void;
}

function EasClaimModal(props: IEasClaimModalProps) {
  const { onClaim, ...modalProps } = props;
  const [isLoading, toggleIsLoading] = useState(false);

  const handleClaim = async () => {
    toggleIsLoading(true);
    await onClaim();
    toggleIsLoading(false);
  };

  return (
    <Modal
      width={360}
      noCloseBtn
      {...modalProps}
      PaperProps={{
        sx: { p: 0 },
      }}
      titleProps={{
        children: 'Claim Verified Badge',
        variant: 'body1',
        fontWeight: 700,
        textAlign: 'center',
        width: '100%',
      }}
    >
      <Stack mt={2} textAlign='center'>
        <Text variant='body2' color='text.250'>
          You just got verified as a builder in crypto! Claim your verified badge now.
        </Text>
      </Stack>

      <Flex gap={1.5} mt={4}>
        <Button disabled={isLoading} onClick={props.onClose} fullWidth variant='outlined' color='primary'>
          Cancel
        </Button>

        <LoadingButton loading={isLoading} onClick={handleClaim} fullWidth variant='contained' color='primary'>
          Claim
        </LoadingButton>
      </Flex>
    </Modal>
  );
}

export default EasClaimModal;
