import { revalidateSSGPage } from '@/utils';
import { getProfileQueryParams } from '@cyber-co/utils';
import {
  GiveRecommendationInput,
  GiveRecommendationResponse_Status,
  api,
  useGiveRecommendationMutation,
} from '@cyber/service/pwa';
import { toast } from '@cyberlab/uikit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export type TCreateRecommendationParams = Omit<GiveRecommendationInput, 'profileId'> & {
  handle: string;
  profileId: string;
};

const ERROR_MSG: { [key in GiveRecommendationResponse_Status]?: string } = {
  [GiveRecommendationResponse_Status.AlreadyRecommended]: 'AlreadyRecommended',
  [GiveRecommendationResponse_Status.InvalidContent]: 'InvalidContent',
  [GiveRecommendationResponse_Status.NotLogin]: 'NotLogin',
  [GiveRecommendationResponse_Status.PermissionDenied]: 'PermissionDenied',
  [GiveRecommendationResponse_Status.UnconnectedProfile]:
    'You need to establish a connection with this person before writing a recommendation!',
  [GiveRecommendationResponse_Status.ProfileNotFound]: 'ProfileNotFound',
};

export const useCreateRecommendation: () => [
  updater: (p: TCreateRecommendationParams) => Promise<{ isSuccess: boolean }>,
  result: any,
] = () => {
  const dispatch: any = useDispatch();

  const [creator, createResult] = useGiveRecommendationMutation();

  const createRecommendation = async (input: GiveRecommendationInput) =>
    creator({ input }).then((data) => {
      if ('data' in data && data.data.giveRecommendation.status === GiveRecommendationResponse_Status.Success) {
        return { isSuccess: true, message: ERROR_MSG[data.data.giveRecommendation.status] };
      }

      return {
        isSuccess: false,
        message: 'data' in data ? ERROR_MSG[data.data.giveRecommendation.status] : 'Something went wrong!',
      };
    });

  const handleUpdate = useCallback(
    async ({ profileId, handle, ...input }: TCreateRecommendationParams) => {
      const res = await createRecommendation({ ...input, profileId });

      if (res.isSuccess) {
        await dispatch(api.endpoints.getProfile.initiate(getProfileQueryParams(handle), { forceRefetch: true }));

        toast.success('Give Recommendation Successfully!');
        revalidateSSGPage(handle);
      } else {
        toast.error(res.message);
      }

      return res;
    },
    [creator],
  );

  return [handleUpdate, createResult];
};
