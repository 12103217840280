import { useAddresses } from '@/hooks';
import { generateUrl } from '@/utils';
import { fixIPFSUrl, fixNFTIPFSURL } from '@cyber-co/utils';
import { ProfileType } from '@cyber/service/pwa';
import { Avatar, Box, CircularProgress, Stack, SxProps, styled } from '@cyberlab/uikit';

export type TAvatarShape = 'circular' | 'hexagon' | 'square';
interface BaseAvatarProps {
  src: string;
  size: number | string;
  sx?: SxProps;
  alt?: string;
  variant?: TAvatarShape;
  bordered?: boolean;
  borderWidth?: number | string;
  hexgonWrapperSx?: SxProps;
  hexgonAvatarSx?: SxProps;
  hexgonBorderColor?: string;
}

type AvatarFrameProps = {
  tokenId?: string | null;
  children: JSX.Element;
  size?: number | string;
  animated?: boolean;
  sx?: SxProps;
};

const AvatarFrame = ({ children, size, tokenId, animated, sx }: AvatarFrameProps) => {
  return tokenId ? (
    <Box sx={{ position: 'relative' }}>
      {children}
      <Stack
        sx={{
          width: size,
          height: size,
          position: 'absolute',
          top: '51%',
          left: '49%',
          transform: 'translate(-50%, -50%)',
          alignItems: 'center',
          justifyContent: 'center',
          ...sx,
        }}
      >
        <img
          src={`/assets/avatar-frames/${animated ? `${tokenId}.gif` : `${tokenId}.png`}`}
          width='140%'
          alt='avatar-frame'
        />
      </Stack>
    </Box>
  ) : (
    children
  );
};

function BaseAvatar(props: BaseAvatarProps) {
  const {
    src,
    size = 30,
    variant = 'circular',
    alt = '',
    bordered = false,
    borderWidth = 1,
    hexgonBorderColor = '#fff',
  } = props;

  const convert2Number = (value: number | string): number => {
    if (isString(value)) return parseInt(value, 10);
    return value;
  };

  const safeParam = (value: number | string): string => {
    if (isString(value)) return value;
    return `${value.toString()}px`;
  };

  const isString = (value: string | number): value is string => {
    return Object.prototype.toString.call(value) === '[object String]';
  };

  const _borderWidth = safeParam(borderWidth);
  const _size = safeParam(size);

  // base hexagon shape
  const HexagonShape = styled('div', { name: 'HexagonShape', shouldForwardProp: (p) => p !== 'size' })<{
    size: string;
  }>(({ size }) => ({
    width: size,
    height: size,
    overflow: 'hidden',
    boxSizing: 'content-box',
    clipPath:
      'polygon(92.32051% 40%, 93.79385% 43.1596%, 94.69616% 46.52704%, 95% 50%, 94.69616% 53.47296%, 93.79385% 56.8404%, 92.32051% 60%, 79.82051% 81.65064%, 77.82089% 84.50639%, 75.35575% 86.97152%, 72.5% 88.97114%, 69.3404% 90.44449%, 65.97296% 91.34679%, 62.5% 91.65064%, 37.5% 91.65064%, 34.02704% 91.34679%, 30.6596% 90.44449%, 27.5% 88.97114%, 24.64425% 86.97152%, 22.17911% 84.50639%, 20.17949% 81.65064%, 7.67949% 60%, 6.20615% 56.8404%, 5.30384% 53.47296%, 5% 50%, 5.30384% 46.52704%, 6.20615% 43.1596%, 7.67949% 40%, 20.17949% 18.34936%, 22.17911% 15.49361%, 24.64425% 13.02848%, 27.5% 11.02886%, 30.6596% 9.55551%, 34.02704% 8.65321%, 37.5% 8.34936%, 62.5% 8.34936%, 65.97296% 8.65321%, 69.3404% 9.55551%, 72.5% 11.02886%, 75.35575% 13.02848%, 77.82089% 15.49361%, 79.82051% 18.34936%)',
  }));

  // make sure avatar is display inside hexagon
  const HexagonAvatarWrapper = styled(HexagonShape, {
    name: 'HexagonAvatarWrapper',
  })(({}) => ({
    background: '#000',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  // background dom element behind the actual avatar comp, the diff between HexagonAvatarBg and HexagonAvatarWrapper is used as border
  const HexagonAvatarBg = styled(HexagonShape, { name: 'HexagonAvatarBg' })(() => ({
    background: hexgonBorderColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  /** ---------------------------- Hexgon Avatar ------------------------------------ */

  if (variant === 'hexagon') {
    const { hexgonWrapperSx = {}, hexgonAvatarSx = {} } = props;
    let _innerSize = safeParam(size);

    if (bordered) {
      _innerSize = safeParam(convert2Number(_size) - convert2Number(borderWidth) * 2);
    }

    return (
      <HexagonAvatarBg size={_size} sx={hexgonWrapperSx}>
        <HexagonAvatarWrapper size={_innerSize} sx={hexgonAvatarSx}>
          <Avatar src={fixIPFSUrl(src)} sx={{ width: _innerSize, height: _innerSize, ...props.sx }} />
        </HexagonAvatarWrapper>
      </HexagonAvatarBg>
    );
  }
  /** ---------------------------- Circular Avatar ------------------------------------ */

  let _sx: SxProps = {
    width: _size,
    height: _size,
    border: bordered ? `${_borderWidth} solid` : 'none',
    borderColor: 'bg.0',
    borderRadius: variant === 'square' ? 1 : '50%',
    ...props.sx,
  };

  let _attrs = {
    src,
    alt,
    sx: _sx,
    variant,
  };

  return <Avatar {..._attrs} />;
}
interface AvatarProps {
  src: string | undefined | null;
  loading?: boolean;
  variant?: TAvatarShape; //  hexagon or circular
  bordered?: boolean;
  borderWidth?: number | string;
  sx?: SxProps; // for circular avatar componrnt
  size?: number | string; // size for avatar itself, without border (box-sizing:content-border liked)
  type?: ProfileType;
  alt?: string;
  hexgonWrapperSx?: SxProps; // wrapper sx for hexagon background wrapper
  hexgonAvatarSx?: SxProps; // wrapper sx for hexagon avatar wrapper
  hexgonBorderColor?: string;
  theme?: 'light' | 'dark';
  address?: string | null;
  onClick?: () => void;
  frame?: {
    tokenId?: string | null;
    size?: number | string;
    animated?: boolean;
  };
  handle?: string;
}

export default function GlobalAvatar(props: AvatarProps) {
  const {
    src,
    loading = false,
    size = 30,
    type = ProfileType.Personal,
    sx = {},
    alt = '',
    bordered = false,
    borderWidth = 4,
    theme = 'light',
    variant = 'circular',
    hexgonAvatarSx = {},
    hexgonWrapperSx = {},
    hexgonBorderColor = '#fff',
    address,
    frame,
    onClick,
  } = props;

  const { eoa } = useAddresses();

  const composeUrl = (url: string | undefined | null) => {
    if (url === null) {
      return '';
    }

    if (!url) {
      return generateUrl(address || eoa, type);
    }

    if (url.startsWith('blob:')) return url;

    return fixNFTIPFSURL(url);
  };

  const convert2Number = (value: number | string): number => {
    if (isString(value)) return parseInt(value, 10);
    return value;
  };

  const isString = (value: string | number): value is string => {
    return Object.prototype.toString.call(value) === '[object String]';
  };

  return loading ? (
    <CircularProgress size={convert2Number(size) / 2} sx={{ color: theme === 'light' ? '#000' : '#fff' }} />
  ) : (
    <Box
      onClick={onClick ? onClick : () => null}
      className='global-avatar-with-frame'
      sx={{ cursor: onClick ? 'pointer' : 'auto' }}
    >
      <AvatarFrame
        size={frame?.size || size}
        tokenId={frame?.tokenId}
        animated={frame?.animated}
        sx={{ cursor: onClick ? 'pointer' : 'auto' }}
      >
        <BaseAvatar
          src={composeUrl(src)}
          size={size}
          sx={sx}
          alt={alt}
          bordered={bordered}
          borderWidth={borderWidth}
          variant={type === ProfileType.Organization ? 'square' : variant}
          hexgonAvatarSx={hexgonAvatarSx}
          hexgonWrapperSx={hexgonWrapperSx}
          hexgonBorderColor={hexgonBorderColor}
        />
      </AvatarFrame>
    </Box>
  );
}
