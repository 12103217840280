import { Proposal as TProposal } from '@cyber/service/snapshot';
import { Flex, Stack, Text, styled } from '@cyberlab/uikit';
import formatDistance from 'date-fns/formatDistance';

const getProposalLink = (id: string, spaceId: string) => `https://snapshot.org/#/${spaceId}/proposal/${id}`;

const Container = styled(Stack)(({ theme: { palette, shape, spacing } }) => ({
  border: `1px solid ${palette.bg?.[100]}`,
  borderRadius: shape.borderRadius,
  padding: spacing(1.5),
  gap: spacing(1.5),
}));

const StatusTag = (props: { status: TProposal['state'] }) => {
  const { status } = props;
  const color = status === 'active' ? 'success.main' : 'text.250';
  const bgcolor = status === 'active' ? 'rgba(144, 222, 83, 0.10)' : 'rgba(255, 255, 255, 0.10)';

  return (
    <Flex bgcolor={bgcolor} py={0.5} px={1} alignItems='center' border='1px solid' borderRadius={1} borderColor={color}>
      <Text variant='caption' textTransform='capitalize' color={color}>
        {status}
      </Text>
    </Flex>
  );
};

const ProposalTime = (props: { status: TProposal['state']; end: number }) => {
  const { status, end } = props;

  const getOffset = () => {
    const current = new Date().getTime();

    return formatDistance(current, end * 1000);
  };

  const tag = status === 'active' ? 'left' : 'ago';

  return (
    <Text variant='caption' color='text.250'>
      {getOffset()} {tag}s
    </Text>
  );
};

const Status = (props: { status: TProposal['state']; end?: number }) => {
  return (
    <Flex alignItems='center' justifyContent='space-between'>
      <StatusTag status={props.status} />
      <ProposalTime status={props.status} end={props.end || 0} />
    </Flex>
  );
};

function Proposal(props: { data: TProposal; spaceId: string }) {
  const { data, spaceId } = props;
  return (
    <Container onClick={() => window.open(getProposalLink(data.id || '', spaceId || ''))}>
      <Text bold variant='body1'>
        {data.title}
      </Text>

      <Text lines={3} color='text.250' variant='body2'>
        {data.body || data.title}
      </Text>

      <Status status={data.state} end={data.end} />
    </Container>
  );
}

export default Proposal;
