import { Skill } from '@cyber/service/pwa';
import { Divider, Flex, Text } from '@cyberlab/uikit';

const SkillTag = (props: { data: Skill; onClick?: () => void }) => {
  const { data, onClick } = props;

  return (
    <Flex
      alignItems='center'
      width='fit-content'
      gap={1}
      px={1.5}
      border='1px solid'
      borderColor='text.100'
      borderRadius={1}
      height={28}
      onClick={onClick}
    >
      <Text variant='body2' color='text.100'>
        {data.name}
      </Text>
      <Divider sx={{ height: 16, mt: '5px' }} flexItem orientation='vertical' />
      <Text variant='body2' color='text.250'>
        {data.endorserCount}
      </Text>
    </Flex>
  );
};

export default SkillTag;
