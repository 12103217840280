import { Event } from '@cyber/service/pwa';
import { Text } from '@cyberlab/uikit';
import { useState } from 'react';
import ShowMore from '../core/ShowMore';
import EventMiniCard from './EventMiniCard';

function EventList(props: { events: Event[] }) {
  const { events } = props;

  const hasMore = events.length > 3;
  const [showMore, toggleShowMore] = useState(false);

  if (events.length < 1) {
    return (
      <Text my={5} textAlign='center' color='text.250'>
        No Events
      </Text>
    );
  }

  return (
    <>
      {events.slice(0, showMore ? events.length : 3).map((event) => (
        <EventMiniCard key={event.id} event={event} />
      ))}
      {hasMore ? (
        <ShowMore
          wrapperProps={{ mt: 0 }}
          isShowMore={showMore}
          setShowMore={() => toggleShowMore((state) => !state)}
        />
      ) : null}
    </>
  );
}

export default EventList;
