import { fixHttpLink, isValidLink } from '@cyber-co/utils';
import { useGetLinkPreviewQuery } from '@cyber/service/api';
import { SuperLink, SuperLinkType } from '@cyber/service/pwa';
import { Box, RatioBox, Stack, Text } from '@cyberlab/uikit';
import { Icon } from '@link3-pwa/icons';
import { PropsWithChildren } from 'react';
import { EditIcon } from '../core/EditButton';
import Container from './Container';
import EmbededTweet from './EmbededTweet';

const EditButtonWrapper = (props: PropsWithChildren<{ onClick: any }>) => (
  <Box
    zIndex={2}
    position='absolute'
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      props.onClick();
    }}
    top={0}
    right={0}
    width={48}
    height={48}
  >
    {props.children}
  </Box>
);

const ICON_MAP = new Map([
  [SuperLinkType.Mirror, <Icon name='icon-mirror' size={32} />],
  [SuperLinkType.Medium, <Icon name='icon-medium' size={32} />],
  [SuperLinkType.Tweet, <Icon name='icon-Twitter' size={32} />],
  [SuperLinkType.Youtube, <Icon name='icon-YouTube' size={32} />],
  [SuperLinkType.Substack, <Icon name='icon-substack' size={32} />],
  [SuperLinkType.Other, <Icon name='icon-link' size={32} />],
]);

function EmbeddedBlog(props: { data: SuperLink; onEdit?: () => void; couldEdit: boolean }) {
  const { data, couldEdit } = props;
  const { onEdit } = props;

  // 这里是为了兼容link3老的content数据
  const { metaData } = useGetLinkPreviewQuery(encodeURIComponent(data.link), {
    skip: !data.link || data.type === SuperLinkType.Tweet || (data && !!data.title),
    selectFromResult({ currentData }) {
      return {
        metaData: {
          title: currentData?.data.title || '',
          imageUrl: currentData?.data?.img || '',
          description: currentData?.data?.desc || '',
        },
      };
    },
  });

  if (data.type === SuperLinkType.Tweet) {
    return (
      <EmbededTweet
        link={data.link}
        editButton={
          onEdit && couldEdit ? (
            <EditButtonWrapper onClick={onEdit}>
              <EditIcon />
            </EditButtonWrapper>
          ) : null
        }
      />
    );
  }

  return (
    <Container href={data.link} target='_blank'>
      <Stack gap={1.5} width='50%'>
        {ICON_MAP.get(data.type)}

        <Text lines={3} variant='body1' color='text.100'>
          {data.title || metaData.title}
        </Text>

        {data.link && isValidLink(data.link) ? (
          <Text variant='caption' color='text.250'>
            {new URL(fixHttpLink(data.link)).hostname}
          </Text>
        ) : null}
      </Stack>

      <RatioBox ratio={136 / 156} sx={{ width: '42%' }}>
        <Box
          flexShrink={0}
          width='100%'
          height='100%'
          borderRadius={1}
          component='img'
          src={data.imageUrl || metaData.imageUrl}
          sx={{ objectFit: 'cover' }}
        />
      </RatioBox>

      {onEdit && couldEdit ? (
        <EditButtonWrapper onClick={onEdit}>
          <EditIcon />
        </EditButtonWrapper>
      ) : null}
    </Container>
  );
}

export default EmbeddedBlog;
