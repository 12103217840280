import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
  format,
} from 'date-fns';

export const getDifference = (start: Date, end: Date = new Date()) => {
  const diffYears = differenceInYears(end, start);
  if (diffYears) return `${diffYears} ${diffYears == 1 ? 'year' : 'years'}`;

  const diffMonths = differenceInMonths(end, start);
  if (diffMonths) return `${diffMonths} ${diffMonths == 1 ? 'month' : 'months'}`;

  const diffWeeks = differenceInWeeks(end, start);
  if (diffWeeks) return `${diffWeeks} ${diffWeeks == 1 ? 'week' : 'weeks'}`;

  const diffDays = differenceInDays(end, start);
  if (diffDays) return `${diffDays} ${diffDays == 1 ? 'day' : 'days'}`;

  const diffHours = differenceInHours(end, start);
  if (diffHours) return `${diffHours} ${diffHours == 1 ? 'hour' : 'hours'}`;

  const diffMins = differenceInMinutes(end, start);
  if (diffMins) return `${diffMins} ${diffMins == 1 ? 'min' : 'mins'}`;

  return '0 days';
};
export const formatTime = (start: Date, end?: Date) => `${format(start, 'MMM yyyy')} - 
${end ? format(end, 'MMM yyyy') : 'Present'} · ${getDifference(start, end)}`;
