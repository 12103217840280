import Wrapper from '@/component/Wrapper';
import { Box, Divider, Flex, Skeleton, Text } from '@cyberlab/uikit';

function BlockSkeleton() {
  return (
    <Box>
      <Wrapper pt={4}>
        <Flex alignItems='center' justifyContent='space-between'>
          <Skeleton>
            <Text lines={1} variant='body1' bold color='text.100'>
              title title title title title
            </Text>
          </Skeleton>
        </Flex>
      </Wrapper>
      <Divider />

      <Wrapper pt={4}>
        <Skeleton>
          <Text lines={1} variant='body1' bold color='text.100'>
            title title title title title title title title title title title title title title title title title title
            title title title title title title title
          </Text>
        </Skeleton>
        <Skeleton>
          <Text lines={1} variant='body1' bold color='text.100'>
            title title title title title title title title title title title title title title title title title title
            title title title title title title title
          </Text>
        </Skeleton>
        <Skeleton>
          <Text lines={1} variant='body1' bold color='text.100'>
            title title title title title title title t
          </Text>
        </Skeleton>
      </Wrapper>
    </Box>
  );
}

export default BlockSkeleton;
