import { Recommendation } from '@cyber/service/pwa';
import { Text } from '@cyberlab/uikit';
import { ReactNode, useState } from 'react';
import ShowMore from '../core/ShowMore';
import RecommendationItem from './RecommendationItem';

function RecommendationList(props: { data?: Recommendation[]; renderIcon?: (data: Recommendation) => ReactNode }) {
  const { data = [], renderIcon } = props;
  const hasMore = data.length > 3;
  const [showMore, toggleShowMore] = useState(false);

  if (data.length < 1) {
    return (
      <Text textAlign='center' color='text.250' my={5} mb={2}>
        No Recommendations
      </Text>
    );
  }
  return (
    <>
      {data.slice(0, showMore ? data.length : 4).map((rec) => (
        <RecommendationItem key={rec.user.id} data={rec} renderIcon={renderIcon} />
      ))}

      {hasMore ? (
        <ShowMore
          wrapperProps={{ my: 0 }}
          isShowMore={showMore}
          setShowMore={() => toggleShowMore((state) => !state)}
        />
      ) : null}
    </>
  );
}

export default RecommendationList;
