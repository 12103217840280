import {
  TCreateBlockParams,
  TUpdateBlockParams,
  useCreateBlocks,
  useDeleteBlocks,
  useEnhancedUpdatePersonal,
  useUpdateBlocks,
} from '@/hooks/profile';
import { useCreateRecommendation } from '@/hooks/profile/useCreateRecommendation';
import { useEnhancedUpdateOrgProfile } from '@/hooks/profile/useEnhancedUpdateOrgProfile';
import { useUpdateEducation } from '@/hooks/profile/useUpdateEducation';
import { useUpdateWorkExp } from '@/hooks/profile/useUpdateWorkExp';
import { Block, BlockType } from '@cyber/service/pwa';
import { FullScreenDrawer, LoadingButtonProps } from '@cyberlab/uikit';
import { ModalProps } from '@cyberlab/uikit/src/Modal';
import { getUnixTime, isSameDay } from 'date-fns';
import { useRouter } from 'next/router';
import { PropsWithChildren, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { TFormValue as TEducationForm } from '../Education/Education.edit';
import { TRecommendationFormValue } from '../Recommendation/Recommendation.edit/Create';
import { TFormValue as TWorkForm } from '../Work/Work.edit';
import DeleteSectionButton from './DeleteSectionButton';

export interface IProfileFormDrawerProps extends Pick<ModalProps, 'open' | 'onClose' | 'title'> {
  blockData?: Block & any;
  profileId: string;
  type?: 'block' | 'perProfile' | 'orgProfile' | 'education' | 'workExp' | 'recommendation';
  deleteButtonProps?: LoadingButtonProps;
  saveButtonProps?: LoadingButtonProps;
  noDeleteButton?: boolean;
}

function ProfileFormDrawer(props: PropsWithChildren<IProfileFormDrawerProps>) {
  const {
    open,
    title,
    children,
    blockData,
    profileId,
    deleteButtonProps,
    type = 'block',
    saveButtonProps,
    noDeleteButton,
  } = props;
  const { onClose } = props;

  const { query } = useRouter();

  const handle = (query?.handle || '').toString();

  const { handleSubmit, reset, formState } = useFormContext();

  const [create] = useCreateBlocks();
  const [update] = useUpdateBlocks();
  const [deleteCaller] = useDeleteBlocks();

  const [updatePersonalBasicInfo] = useEnhancedUpdatePersonal();
  const [updateOrgBasicInfo] = useEnhancedUpdateOrgProfile();

  const [updateEducation] = useUpdateEducation();
  const [updateWork] = useUpdateWorkExp();
  const [giveRecommendation] = useCreateRecommendation();

  const showCommonDeleteButton =
    type === 'block' &&
    blockData?.id &&
    blockData?.type !== BlockType.Superlink &&
    blockData?.type !== BlockType.Skill &&
    !noDeleteButton;

  const createBlock = async (p: TCreateBlockParams) => {
    return await create(p);
  };

  const updateBlock = async (p: TUpdateBlockParams) => {
    return await update(p);
  };

  const deleteBlock = async () => {
    if (!showCommonDeleteButton) return;
    await deleteCaller({ id: blockData.id, handle });
  };

  const saveBlock = (v: any) => {
    if (blockData && blockData.id)
      return updateBlock({
        id: blockData.id,
        handle,
        input: {
          displayName: blockData.displayName,
          index: blockData.index,
          ...v,
        },
      });

    return createBlock({
      profileId: profileId,
      handle,
      input: {
        displayName: blockData?.displayName,
        // index: blockData?.index || 0,
        ...v,
      },
    });
  };

  const submit = (v: any) => {
    switch (type) {
      case 'block':
        return saveBlock(v);

      case 'perProfile':
        return updatePersonalBasicInfo({
          id: profileId,
          handle,
          input: v,
        });

      case 'orgProfile':
        return updateOrgBasicInfo({
          id: profileId,
          handle,
          input: v,
        });

      case 'education':
        const { startDate, endDate, orgTwitter, ...restEduValue } = v as TEducationForm;

        return updateEducation({
          ...restEduValue,
          startDate: getUnixTime(startDate),
          endDate: endDate ? (isSameDay(new Date(), endDate) ? undefined : getUnixTime(endDate)) : undefined,
          orgAvatar: orgTwitter.avatar,
          orgName: orgTwitter.displayName,
          orgTwitterId: orgTwitter.twitterId,
          orgTwitterHandle: orgTwitter.twitterHandle,
          handle,
          profileId,
        });

      case 'workExp':
        const { startDate: wStartDate, endDate: wEndDate, orgTwitter: wOrgTwitter, ...restWorkValue } = v as TWorkForm;

        return updateWork({
          ...restWorkValue,
          startDate: getUnixTime(wStartDate),
          endDate: wEndDate ? (isSameDay(new Date(), wEndDate) ? undefined : getUnixTime(wEndDate)) : undefined,
          orgAvatar: wOrgTwitter.avatar,
          orgName: wOrgTwitter.displayName,
          orgTwitterId: wOrgTwitter.twitterId,
          orgTwitterHandle: wOrgTwitter.twitterHandle,
          handle,
          profileId,
        });

      case 'recommendation':
        const { content } = v as TRecommendationFormValue;

        return giveRecommendation({
          handle,
          profileId,
          content,
        });

      default:
        return saveBlock(v);
    }
  };

  const onSubmit = () =>
    handleSubmit(async (v) => {
      const res = await submit(v);

      if (res?.isSuccess && onClose) {
        onClose();
      }
    })();

  // logic for reseting form value
  useEffect(() => {
    return () => {
      reset({});
    };
  }, []);

  return (
    <FullScreenDrawer
      open={open}
      onClose={onClose}
      title={title}
      buttonProps={{
        onClick: onSubmit,
        loading: formState.isSubmitting,
        ...saveButtonProps,
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        {children}
      </form>
      {showCommonDeleteButton ? (
        <DeleteSectionButton fullWidth {...deleteButtonProps} onDelete={deleteBlock}>
          {deleteButtonProps?.children || 'Delete this Section'}
        </DeleteSectionButton>
      ) : null}
    </FullScreenDrawer>
  );
}

export default ProfileFormDrawer;
