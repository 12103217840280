import Credential from '@/component/Profile/blocks/Credential';
import Education from '@/component/Profile/blocks/Education';
import FeaturedContents from '@/component/Profile/blocks/FeaturedContents';
import Link3Event from '@/component/Profile/blocks/Link3Event';
import Link3Post from '@/component/Profile/blocks/Link3Post';
import Links from '@/component/Profile/blocks/Links';
import NFT from '@/component/Profile/blocks/NFTs';
import OpenSea from '@/component/Profile/blocks/OpenSea';
import Skills from '@/component/Profile/blocks/Skills';
import Snapshot from '@/component/Profile/blocks/Snapshot';
import Twitter from '@/component/Profile/blocks/Twitter';
import W3st from '@/component/Profile/blocks/W3st';
import Works from '@/component/Profile/blocks/Work';
import { DEFAULT_BLOCK_TITLE } from '@/configs/profile';
import { useCouldEditProfile } from '@/hooks/profile';
import { Block, BlockType, ProfileType } from '@cyber/service/pwa';
import { ComponentType, useMemo } from 'react';
import { IBlockProps } from '../types';
import Contact from './Contact';
import Recommendation from './Recommendation';

type TProps = IBlockProps & {
  blocks: Block[];
  profileType: ProfileType;
};

const ORG_BLOCK_MAP = new Map<BlockType, ComponentType<any>>([
  [BlockType.Event, Link3Event],
  [BlockType.Posts, Link3Post],
  [BlockType.Link, Links],
  [BlockType.Superlink, FeaturedContents],
  [BlockType.NftCollection, OpenSea],
  [BlockType.Snapshot, Snapshot],
  [BlockType.Twitter, Twitter],
]);

const PERSONAL_BLOCK_MAP = new Map<BlockType, ComponentType<any>>([
  [BlockType.WorkExperience, Works],
  [BlockType.Education, Education],
  [BlockType.Skill, Skills],
  [BlockType.Link, Links],
  [BlockType.Superlink, FeaturedContents],
  [BlockType.Recommendation, Recommendation],
  [BlockType.Contact, Contact],
  [BlockType.NftGallery, NFT],
  [BlockType.W3StGallery, W3st],
  [BlockType.Credential, Credential],
  [BlockType.Twitter, Twitter],
]);

// the block could be added more than once
const MULI_BLOCKS = [BlockType.Twitter];

const getTitle = (type: BlockType) => DEFAULT_BLOCK_TITLE[type];

const counldAddBlock = (type: BlockType, currentBlocks: BlockType[]) =>
  currentBlocks.includes(type) && !MULI_BLOCKS.includes(type);

const renderAddBlocks = (props: TProps, couldEdit: boolean = false) => {
  const { blocks, profileType, ...restProps } = props;
  const currentBlocks = blocks.map((b) => b.type);
  const BLOCK_MAP = profileType !== ProfileType.Organization ? PERSONAL_BLOCK_MAP : ORG_BLOCK_MAP;

  const children = [];

  if (!couldEdit) {
    if (profileType === ProfileType.Organization) {
      return null;
    } else if (profileType === ProfileType.Personal && !blocks.find((b) => b.type === BlockType.Recommendation)) {
      return (
        <Recommendation {...restProps} data={{ displayName: getTitle(BlockType.Recommendation) } as any} isCreate />
      );
    } else {
      return null;
    }
  }

  for (const blockType of BLOCK_MAP.keys()) {
    const Comp = BLOCK_MAP.get(blockType);
    if (!Comp || counldAddBlock(blockType, currentBlocks)) continue;

    children.push(<Comp {...restProps} data={{ displayName: getTitle(blockType) } as any} isCreate />);
  }

  return children;
};

function AddBlocks(props: TProps) {
  const { couldEdit } = useCouldEditProfile();

  const children = useMemo(() => renderAddBlocks(props, couldEdit), [props.blocks, couldEdit]);

  return <>{children}</>;
}

export default AddBlocks;
